import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  StudentCycleCasesPage,
  StudentCycleCompletionPage,
  StudentCycleSkillsPage,
  StudentCycleTheoreticalPartsPage
} from '../components';
import { Colors, ComboBox, DatePicker, DropDown, ErrorMessage, OverviewPage, StackLayout, Text, QrScanner, TextBox } from '../../../../ui';
import { Button } from '@progress/kendo-react-buttons';
import CheckMarkIcon from '../../../../ui/icons/check-mark-icon';
import NotebookIcon from '../../../../ui/icons/notebok-icon';
import OpenBookIcon from '../../../../ui/icons/open-book-icon';
import PaperAndPencilIcon from '../../../../ui/icons/paper-and-pencil-icon';
import { InlineBadge, ValueItem } from '../../components';
import {
  loadStudentCycleOverview,
  selectTab,
  toggleStudentCycleOverviewSummaryExpanded,
  increaseSkills,
  clearSkills,
  expandSkill,
  openSkillEditDialog,
  saveSkill,
  openSkillDeleteDialog,
  deleteSelectedSkill,
  increaseCases,
  clearCases,
  expandCase,
  openCaseEditDialog,
  openCaseDeleteDialog,
  deleteSelectedCase,
  completeStudentCycleTheoreticalPart,
  openSkillRegisterDialog,
  closeEditDialog,
  registerSkills,
  filterAvailableDepartments,
  filterAvailableManagers,
  closeDeleteDialog,
  openCaseRegisterDialog,
  saveCase,
  registerCases,
  closeAddDialog,
  filterAvailableCycles,
  loadAvailableCycleSkills,
  loadAvailableCycleCases,
  openAdditionalCycleSkillAddDialog,
  saveAdditionalCycleSkill,
  openAdditionalCycleSkillDeleteDialog,
  deleteSelectedAdditionalCycleSkill,
  openAdditionalCycleCaseAddDialog,
  saveAdditionalCycleCase,
  openAdditionalCycleCaseDeleteDialog,
  deleteSelectedAdditionalCycleCase,
  openRegisterTheoreticalPartDialog,
  closeRegisterTheoreticalPartDialog,
  submitApprovalRequest,
  openConfirmApprovalRequestDialog,
  closeConfirmApprovalRequestDialog,
  closeCycleConfirmSubmitDialog,
  expandCycleStatusHistory,
  closeCycleStatusHistory,
  openCycleConfirmSubmitDialog,
  submitCycle,
  loadStudentCycleSkills,
  loadStudentCycleCases,
  loadStudentCycleTheoreticalParts,
  loadStudentCycleCompletion,
  clearStudentCycleOverview,
  submitStudyPlanCycleDepartmentStudentDesignation,
  openSubmitDepartmentDesignationDialog,
  closeSubmitDepartmentDesignationDialog
} from '../actions';
import { Badge } from '@progress/kendo-react-indicators';
import FormDialog from '../../../../ui/components/application/form-dialog';
import { Field } from '@progress/kendo-react-form';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { STUDENT_CYCLE_OVERVIEW_DIALOG_TYPES as DialogTypes } from '../../../../resources/StudentCycleOverviewDialogType';
import { addDay } from '../../../../utils/validations';
import { SURVEY_TYPES } from '../../../../resources/surveyType';
import { getStudentCycleStatusColor } from '../../../../utils/studyPlanCycleDepartmentStudentStatusColors';
import { withRouter } from '../../../../components/withRouter';
import { DatePeriodFormatter } from '../../components/Formatter';
import { validateEmail } from '../../../../utils/emailValidationHelper';
import { validateStampNumber } from '../../../../utils/stampNumberValidationHelper';
import { validateNameSurname } from '../../../../utils/nameSurnameValidatorHelper';
import { DESIGNATION_STATUS } from '../../../../resources/designationStatus';

const TabStripContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

const TabStripItem = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.04);
  }  
`;

const TabStripBox = styled(StackLayout).attrs(props => ({
  orientation: 'vertical',
  align: 'center',
  rowGap: '0'
}))`
  cursor: pointer;
  ${(props) => props.selected ? ` 
    &:after{
      border-bottom-width: 2px;
      border-width: 2px;
      border-color: #0D84C6;
      display: block;
      content: "";
      border-style: solid;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }` : ''
};
`;

const TabStripText = styled(Text).attrs(props => ({
  variant: 'caption',
  textColor: props.theme.gray80,
  truncate: true
}))`
  font-size: 10px;
  text-transform: uppercase;
`;

const TabStripIcon = styled.div`
  position: relative;
`;

const BadgeIndicator = styled(Badge)`
  z-index: 0;
`;

const DepartmentContainer = styled.div`
  display: flex;
  white-space: break-spaces
`;

const DepartmentLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
`;

const StyledButton = styled(Button)`
  width: 100px;
  margin: 2px;
  height: 36px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class StudentCycleOverview extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSkillsIncrease = this.handleSkillsIncrease.bind(this);
    this.handleSkillExpand = this.handleSkillExpand.bind(this);
    this.handleCasesIncrease = this.handleCasesIncrease.bind(this);
    this.handleCaseExpand = this.handleCaseExpand.bind(this);
    this.handleEditDialogSubmit = this.handleEditDialogSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
    this.handleEditDialogValidate = this.handleEditDialogValidate.bind(this);
    this.handleAddDialogSubmit = this.handleAddDialogSubmit.bind(this);
    this.handleAddDialogCycleChange = this.handleAddDialogCycleChange.bind(this);
    this.handleEvaluateManagerClick = this.handleEvaluateManagerClick.bind(this);
    this.handleRequestManagerApprovalClick = this.handleRequestManagerApprovalClick.bind(this);
    this.handleEvaluateCycleClick = this.handleEvaluateCycleClick.bind(this);
    this.handlePreviewCycleManagerSurveyClick = this.handlePreviewCycleManagerSurveyClick.bind(this);
    this.handleCompetencyClick = this.handleCompetencyClick.bind(this);
    this.handlePreviewDepartmentDesignationClick = this.handlePreviewDepartmentDesignationClick.bind(this);
    this.state = {
      shouldPreserveSelectedTab: false
    };
  }

  async componentDidMount() {
    const intlService = provideIntlService(this);
    await this.props.loadStudentCycleOverview({
      ...this.props.params,
      intlService,
      studentId: this.props.studentId
    });
  }

  componentWillUnmount() {
    this.props.clearStudentCycleOverview({ shouldPreserveSelectedTab: this.state.shouldPreserveSelectedTab });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studentId !== this.props.studentId) {
      this.props.navigate(`/my_studies`);
    }
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    let totalNewSkills = 0;
    let totalNewCases = 0;
    const countNewSkills = (skills) => skills?.forEach(item => totalNewSkills += item.newLevelA + item.newLevelB + item.newLevelC);
    const countNewCases = (cases) => cases?.forEach(item => totalNewCases += item.newCases);
    countNewSkills(this.props.skills);
    countNewSkills(this.props.additionalSkills);
    countNewCases(this.props.cases);
    countNewCases(this.props.additionalCases);
    return (
      <OverviewPage
        title={this.props.cycle?.cycleName}
        subtitle={
          (this.props.cycle?.cycleCode != null ? `${this.props.cycle?.cycleCode} ` : ``) +
          (this.props.cycle?.cycleType != null ? `${localizationService.toLanguageString('cycleType.' + this.props.cycle.cycleType)} ` : ``) +
          (this.props.cycle?.credits != null ? `${this.props.cycle.credits} ${localizationService.toLanguageString('studentCycle.creditsShort')}` : ``)
        }
        onBackClick={this.handleBackClick}
        expandableSummary={true}
        loading={this.props.loading}
        summaryExpanded={this.props.isSummaryExpanded}
        onToggleExpandSummary={this.props.toggleStudentCycleOverviewSummaryExpanded}
        breadcrumbItems={this.props.breadcrumbItems}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('studentCycle.cycleStatus')}
              labelWidth='108px'
            >
              {this.props.cycle &&
                <InlineBadge themeColor={getStudentCycleStatusColor(this.props.cycle.status)}>
                  {localizationService.toLanguageString(`studyPlanCycleDepartmentStudentStatus.${this.props.cycle.status}`)}
                </InlineBadge>
              }
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studentCycle.departments')}
              labelWidth='108px'
            >
              {this.props.availableDepartments?.length
                ? this.props.availableDepartments.map((department, index) => (
                  <DepartmentContainer key={index}>
                    {!this.props.readOnly && !this.props.preview && department.isExternal
                      ?
                      <ButtonContainer>
                        <StyledButton
                          type={'button'}
                          onClick={department.designationStatus === DESIGNATION_STATUS.NOT_SUBMITTED ? () => this.props.openSubmitDepartmentDesignationDialog(department) : () => this.handlePreviewDepartmentDesignationClick(department)}
                        >
                          {department.designationStatus === DESIGNATION_STATUS.NOT_SUBMITTED
                            ? localizationService.toLanguageString(`buttons.submit`)
                            : localizationService.toLanguageString(`buttons.preview`)}
                        </StyledButton>
                      </ButtonContainer>
                      : null
                    }
                    <DepartmentLabel>
                      <Text variant={'body2'} textColor={Colors.GRAY_80}>
                        {`${DatePeriodFormatter(department.dateFrom, department.dateTo, intlService)} ${department.name} `}
                        <Text variant={'caption'} textColor={Colors.GRAY_50}>
                          {`(${localizationService.toLanguageString(`studentCycle.credits`)}: ${department.credits})`}
                        </Text>
                      </Text>
                    </DepartmentLabel>
                  </DepartmentContainer>
                ))
                : '-'
              }
            </ValueItem>
            {this.props.cycle?.statusComment &&
              <ValueItem
                label={localizationService.toLanguageString('studentCycle.cycleStatusComment')}
                labelWidth='108px'
                truncateValue={false}
              >
                {this.props.cycle.statusComment}
              </ValueItem>
            }
            <ValueItem
              label={localizationService.toLanguageString('studentCycle.competencies')}
              labelWidth='108px'
            >
              {this.props.competencies?.length
                ? this.props.competencies.map((competency, index) => (
                  <a className='k-button-link' key={index} href={`/competencies/${competency.competencyId}/overview`} onClick={(e) => this.handleCompetencyClick(e, competency.competencyId)} target='_blank' rel='noreferrer'>{competency.competencyCode}; </a>
                )) : '-'}
            </ValueItem>
          </StackLayout>
        }
        details={
          <ValueItem
            label={localizationService.toLanguageString('studentCycle.period')}
            labelWidth='108px'
          >
            {(this.props.cycle?.dateFrom && this.props.cycle?.dateTo) ? DatePeriodFormatter(this.props.cycle.dateFrom, this.props.cycle.dateTo, intlService) : '-'}
          </ValueItem>
        }
        tabs={
          <>
            <TabStripContainer>
              <TabStripItem selected={this.props.selectedTab === 0} onClick={() => this.handleTabSelect({ selected: 0 })}>
                <TabStripBox selected={this.props.selectedTab === 0}>
                  <TabStripIcon>
                    <PaperAndPencilIcon />
                    {totalNewSkills > 0 && <BadgeIndicator>+{totalNewSkills}</BadgeIndicator>}
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCycle.tabSkills')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 1} onClick={() => this.handleTabSelect({ selected: 1 })}>
                <TabStripBox selected={this.props.selectedTab === 1}>
                  <TabStripIcon>
                    <NotebookIcon />
                    {totalNewCases > 0 && <BadgeIndicator>+{totalNewCases}</BadgeIndicator>}
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCycle.tabCases')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              <TabStripItem selected={this.props.selectedTab === 2} onClick={() => this.handleTabSelect({ selected: 2 })}>
                <TabStripBox selected={this.props.selectedTab === 2}>
                  <TabStripIcon>
                    <OpenBookIcon />
                  </TabStripIcon>
                  <TabStripText>{localizationService.toLanguageString('studentCycle.tabTheoreticalParts')}</TabStripText>
                </TabStripBox>
              </TabStripItem>
              {!this.props.preview &&
                <TabStripItem selected={this.props.selectedTab === 3} onClick={() => this.handleTabSelect({ selected: 3 })}>
                  <TabStripBox selected={this.props.selectedTab === 3}>
                    <TabStripIcon>
                      <CheckMarkIcon />
                    </TabStripIcon>
                    <TabStripText>{localizationService.toLanguageString('studentCycle.tabCycleCompletion')}</TabStripText>
                  </TabStripBox>
                </TabStripItem>
              }
            </TabStripContainer>
          </>
        }
      >
        {this.props.selectedTab === 0 &&
          <StudentCycleSkillsPage
            loading={this.props.studentSkillsLoading}
            readOnly={this.props.readOnly || this.props.preview}
            onLoadStudentCycleSkills={() => this.props.loadStudentCycleSkills({ ...this.props.params, studentId: this.props.studentId })}
            onRegisterSkillsDialogOpen={() => this.props.openSkillRegisterDialog({ localizationService })}
            onEditDialogOpen={(skillIndex, historySkillIndex, isAdditional) => this.props.openSkillEditDialog({ skillIndex, historySkillIndex, isAdditional, localizationService })}
            onDeleteDialogOpen={(skillIndex, historySkillIndex, isAdditional) => this.props.openSkillDeleteDialog({ skillIndex, historySkillIndex, isAdditional, localizationService })}
            onCycleSkillDeleteDialogOpen={(index) => this.props.openAdditionalCycleSkillDeleteDialog({ index, localizationService })}
            onOpenAdditionalSkillAddDialog={() => this.props.openAdditionalCycleSkillAddDialog({ localizationService })}
            data={this.props.skills}
            additionalData={this.props.additionalSkills}
            onIncrease={(skillId, level, isAdditional) => this.props.increaseSkills({ skillId, level, isAdditional })}
            onClear={this.props.clearSkills}
            totalNew={totalNewSkills}
            onSkillExpand={(skillId, isAdditional) => this.props.expandSkill({ skillId, isAdditional })}
          />
        }
        {this.props.selectedTab === 1 &&
          <StudentCycleCasesPage
            loading={this.props.studentCasesLoading}
            readOnly={this.props.readOnly || this.props.preview}
            onLoadStudentCycleCases={() => this.props.loadStudentCycleCases({ ...this.props.params, studentId: this.props.studentId })}
            onRegisterCasesDialogOpen={() => this.props.openCaseRegisterDialog({ localizationService })}
            onEditDialogOpen={(caseIndex, historyCaseIndex, isAdditional) => this.props.openCaseEditDialog({ caseIndex, historyCaseIndex, isAdditional, localizationService })}
            onDeleteDialogOpen={(caseIndex, historyCaseIndex, isAdditional) => this.props.openCaseDeleteDialog({ caseIndex, historyCaseIndex, isAdditional, localizationService })}
            onCycleCaseDeleteDialogOpen={(index) => this.props.openAdditionalCycleCaseDeleteDialog({ index, localizationService })}
            onOpenAdditionalCaseAddDialog={() => this.props.openAdditionalCycleCaseAddDialog({ localizationService })}
            data={this.props.cases}
            additionalData={this.props.additionalCases}
            onIncrease={(cycleCaseId, isAdditional) => this.props.increaseCases({ cycleCaseId, isAdditional })}
            onClear={this.props.clearCases}
            totalNew={totalNewCases}
            onCaseExpand={(cycleCaseId, isAdditional) => this.props.expandCase({ cycleCaseId, isAdditional })}
          />
        }
        {this.props.selectedTab === 2 &&
          <StudentCycleTheoreticalPartsPage
            loading={this.props.studentTheoreticalPartsLoading}
            readOnly={this.props.preview}
            onLoadStudentCycleTheoreticalParts={this.props.loadStudentCycleTheoreticalParts}
            openRegisterTheoreticalPartDialog={this.props.openRegisterTheoreticalPartDialog}
            theoreticalParts={this.props.theoreticalParts}
          />
        }
        {this.props.selectedTab === 3 && this.props.cycle && !this.props.preview &&
          <StudentCycleCompletionPage
            loading={this.props.studentCycleCompletionLoading}
            readOnly={this.props.readOnly || this.props.preview}
            onLoadStudentCycleCompletion={() => {
              this.props.loadStudentCycleSkills({ ...this.props.params, studentId: this.props.studentId });
              this.props.loadStudentCycleCases({ ...this.props.params, studentId: this.props.studentId });
              this.props.loadStudentCycleCompletion({
                studyPlanId: this.props.cycle.studyPlanId,
                studyPlanCycleId: this.props.cycle.studyPlanCycleId,
                studyPlanCycleStudentId: this.props.cycle.id,
                studentId: this.props.studentId
              });
            }}
            managerSurveys={this.props.managerSurveys}
            managerApprovals={this.props.managerApprovals}
            cycleManagerId={this.props.cycle?.cycleManagerId}
            cycleManagerFullName={this.props.cycle?.cycleManagerFullName}
            cycleDepartmentSurveys={this.props.cycleDepartmentSurveys}
            cycleManagerSurvey={this.props.cycleManagerSurvey}
            cycleStatus={this.props.cycle?.status}
            cycleStatusHistory={this.props.cycleStatusHistory}
            isSkillsAndCasesComplete={this.props.skillsCompleted && this.props.casesCompleted}
            openConfirmSubmitDialog={this.props.openCycleConfirmSubmitDialog}
            closeConfirmSubmitDialog={this.props.closeCycleConfirmSubmitDialog}
            isConfirmSubmitDialogVisible={this.props.isConfirmSubmitDialogVisible}
            confirmSubmitDialogErrorMessage={this.props.confirmSubmitDialogErrorMessage}
            isCycleStatusHistoryExpanded={this.props.isCycleStatusHistoryExpanded}
            onEvaluateManagerClick={this.handleEvaluateManagerClick}
            onRequestManagerApprovalClick={this.handleRequestManagerApprovalClick}
            onEvaluateCycleClick={this.handleEvaluateCycleClick}
            onPreviewCycleManagerSurveyClick={this.handlePreviewCycleManagerSurveyClick}
            onSubmitCycleClick={() => this.props.submitCycle({ studentId: this.props.studentId })}
            onStatusHistoryExpand={this.props.expandCycleStatusHistory}
            onStatusHistoryClose={this.props.closeCycleStatusHistory}
            availableDepartments={this.props.availableDepartments}
            cycleRequiresManagersConfirmation={this.props.cycle?.cycleRequiresManagersConfirmation}
            openConfirmApprovalRequestDialog={this.props.openConfirmApprovalRequestDialog}
            closeConfirmApprovalRequestDialog={this.props.closeConfirmApprovalRequestDialog}
            isConfirmApprovalRequestDialogVisible={this.props.isConfirmApprovalRequestDialogVisible}
            selectedManagerForApprovalRequestUser={this.props.selectedManagerForApprovalRequestUser}
            confirmApprovalRequestDialogErrorMessage={this.props.confirmApprovalRequestDialogErrorMessage}
          />
        }
        {this.props.isEditDialogVisible &&
          <FormDialog
            title={this.props.editDialogTitle}
            loading={this.props.editDialogLoading}
            onSubmit={(values) => this.handleEditDialogSubmit(values, localizationService)}
            onCancel={this.props.closeEditDialog}
            validator={this.handleEditDialogValidate}
            key={JSON.stringify(this.props.editDialogForm)}
            initialValues={this.props.editDialogForm}
            validationResult={this.props.editDialogValidationResult}
            errorMessage={this.props.editDialogError}
            successMessage={this.props.editDialogSuccessMessage}
            confirmButtonText={this.props.editDialogConfirmButtonText}
            closeButtonText={localizationService.toLanguageString('buttons.close')}
            render={(formRenderProps) => (
              <StackLayout>
                <Field
                  name='department'
                  component={ComboBox}
                  label={localizationService.toLanguageString('studentCycle.department')}
                  data={this.props.filteredAvailableDepartments}
                  onFilter={this.props.filterAvailableDepartments}
                  textField='nameWithDate'
                  valueField='id'
                  itemRender={(li, itemProps) => {
                    const dataItem = itemProps.dataItem;
                    const itemChildren = (
                      <div className={dataItem.isForeign ? 'k-disabled' : ''}>
                        <Text>{dataItem.name}</Text>
                        <br />
                        <Text textColor={Colors.GRAY_50} variant='caption'>{DatePeriodFormatter(dataItem.dateFrom, dataItem.dateTo, intlService)}</Text>
                      </div>
                    );
                    return React.cloneElement(li, {
                      ...li.props,
                      onClick: dataItem.isForeign ? (e) => { e.preventDefault(); e.stopPropagation(); } : li.props.onClick
                    }, itemChildren);
                  }}
                />
                {!formRenderProps.valueGetter('showMentorFields') &&
                  <Field
                    name='manager'
                    component={ComboBox}
                    label={localizationService.toLanguageString('studentCycle.studentManager')}
                    data={this.props.availableManagers}
                    onFilter={this.props.filterAvailableManagers}
                    textField='name'
                    valueField='id'
                  />
                }
                <Button type='button' onClick={() => this.handleToggleNewMentor(formRenderProps, true)}>
                  {localizationService.toLanguageString(`studentCycle.${formRenderProps.valueGetter('showMentorFields') ? 'chooseExisting' : 'enterNewMentor'}`)}
                </Button>
                {formRenderProps.valueGetter('showMentorFields') &&
                  <>
                    <Field
                      name='stampNumber'
                      label={localizationService.toLanguageString('studentCycle.stampNumber')}
                      component={TextBox}
                    />
                    <Field
                      name='name'
                      label={localizationService.toLanguageString('studentCycle.name')}
                      component={TextBox}
                    />
                    <Field
                      name='surname'
                      label={localizationService.toLanguageString('studentCycle.surname')}
                      component={TextBox}
                    />
                    <Field
                      name='email'
                      label={localizationService.toLanguageString('studentCycle.email')}
                      component={TextBox}
                    />
                  </>
                }
                <Field
                  name='medicalHistoryNumber'
                  label={localizationService.toLanguageString('studentCycle.medicalHistoryNumber')}
                  component={QrScanner}
                />
                <Field
                  name='date'
                  label={localizationService.toLanguageString('studentCycle.date')}
                  component={DatePicker}
                />
              </StackLayout>
            )}
          />
        }
        {this.props.isDeleteDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.handleDeleteDialogClose}>
            <Text>{this.props.deleteDialogConfirmationMessage}</Text>
            {this.props.deleteDialogErrorMessage && <ErrorMessage>{this.props.deleteDialogErrorMessage}</ErrorMessage>}
            <DialogActionsBar>
              <Button onClick={() => this.handleDeleteConfirm(localizationService)} themeColor='primary' disabled={this.props.isDeleteDialogDeleting}>{localizationService.toLanguageString('buttons.delete')}</Button>
              <Button onClick={this.handleDeleteDialogClose} disabled={this.props.isDeleteDialogDeleting}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
        {this.props.isAddDialogVisible &&
          <FormDialog
            title={this.props.addDialogTitle}
            loading={this.props.addDialogLoading}
            width={'500px'}
            onSubmit={this.handleAddDialogSubmit}
            onCancel={this.props.closeAddDialog}
            validator={(values) => this.handleAddDialogValidate(values, localizationService)}
            validationResult={this.props.addDialogValidationResult}
            errorMessage={this.props.addDialogErrorMessage}
            confirmButtonText={localizationService.toLanguageString('buttons.add')}
            closeButtonText={localizationService.toLanguageString('buttons.close')}
            render={(formRenderProps) => (
              <StackLayout>
                <Field
                  name='studyPlanCycleStudent'
                  component={ComboBox}
                  label={localizationService.toLanguageString('studentCycle.cycle')}
                  data={this.props.filteredAvailableCycles}
                  onFilter={this.props.filterAvailableCycles}
                  onChange={this.handleAddDialogCycleChange}
                  textField='name'
                  valueField='id'
                />
                {this.props.addDialogType === DialogTypes.ADD_SKILL &&
                  <Field
                    name='skill'
                    label={localizationService.toLanguageString('studentCycle.skill')}
                    component={DropDown}
                    disabled={formRenderProps.valueGetter('studyPlanCycleStudent') == null}
                    data={this.props.availableCycleSkills}
                    loading={this.props.availableCycleSkillsLoading}
                    textField='name'
                    valueField='id'
                  />
                }
                {this.props.addDialogType === DialogTypes.ADD_CASE &&
                  <Field
                    name='case'
                    label={localizationService.toLanguageString('studentCycle.case')}
                    component={DropDown}
                    disabled={formRenderProps.valueGetter('studyPlanCycleStudent') == null}
                    data={this.props.availableCycleCases}
                    loading={this.props.availableCycleCasesLoading}
                    textField='name'
                    valueField='id'
                  />
                }
              </StackLayout>
            )}
          />
        }
        {this.props.isRegisterTheoreticalPartDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.closeRegisterTheoreticalPartDialog}>
            <Text>{localizationService.toLanguageString('studentCycle.theoreticalPartRegisterDialogMessage')}</Text>
            <DialogActionsBar>
              <Button
                onClick={this.props.completeStudentCycleTheoreticalPart}
                themeColor='primary'>{localizationService.toLanguageString('buttons.confirm')}
              </Button>
              <Button onClick={this.props.closeRegisterTheoreticalPartDialog}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
        {this.props.isSubmitDepartmentDesignationDialogVisible &&
          <Dialog title={localizationService.toLanguageString('custom.confirm')} onClose={this.props.closeSubmitDepartmentDesignationDialog}>
            <Text>{localizationService.toLanguageString('studentCycle.submitDepartmentDesignationDialogMessage')}</Text>
            <DialogActionsBar>
              <Button
                onClick={this.props.submitStudyPlanCycleDepartmentStudentDesignation}
                themeColor='primary'>{localizationService.toLanguageString('buttons.confirm')}
              </Button>
              <Button onClick={this.props.closeSubmitDepartmentDesignationDialog}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </OverviewPage>
    );
  }

  handleCompetencyClick(e, id) {
    e.preventDefault();
    this.props.navigate(`/competencies/${id}/overview`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate(`/my_studies`);
    }
    if (e.id === 'students') {
      this.props.navigate('/students');
    }
    if (e.id === 'student') {
      this.props.navigate(`/students/${this.props.studentId}/overview`);
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleTabSelect(e) {
    this.props.selectTab({ selectedTab: e.selected });
  }

  handleSkillsIncrease(skillId, level) {
    this.props.increaseSkills({ skillId, level });
  }

  handleSkillExpand(id) {
    this.props.expandSkill({ id });
  }

  handleCasesIncrease(cycleCaseId) {
    this.props.increaseCases({ cycleCaseId });
  }

  handleCaseExpand(id) {
    this.props.expandCase({ id });
  }

  handleEditDialogValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.department || !values.department?.id) {
      errors.department = localizationService.toLanguageString('validation.required');
    }
    if (values.showMentorFields) {
      if (!values.stampNumber) {
        errors.stampNumber = localizationService.toLanguageString('validation.required');
      } else {
        if (!validateStampNumber(values.stampNumber)) {
          errors.stampNumber = localizationService.toLanguageString('studentCycle.invalidStampNumber');
        }
      }
      if (!values.name) {
        errors.name = localizationService.toLanguageString('validation.required');
      } else {
        if (!validateNameSurname(values.name)) {
          errors.name = localizationService.toLanguageString('studentCycle.invalidString');
        }
      }
      if (!values.surname) {
        errors.surname = localizationService.toLanguageString('validation.required');
      } else {
        if (!validateNameSurname(values.surname)) {
          errors.surname = localizationService.toLanguageString('studentCycle.invalidString');
        }
      }
      if (validationResult?.errors) {
        errors.name = !modified?.name && !modified.surname ? validationResult.errors?.name : null;
        errors.surname = !modified?.name && !modified.surname ? validationResult.errors?.surname : null;
      }
      if (this.props.cycle.cycleRequiresManagersConfirmation && !values.email) {
        errors.email = localizationService.toLanguageString('validation.required');
      }
      if (values.email && !validateEmail(values.email)) {
        errors.email = localizationService.toLanguageString('validation.invalidEmailFormat');
      }
    } else {
      if (!values.manager || !values.manager?.id) {
        errors.manager = localizationService.toLanguageString('validation.required');
      }
    }
    if (!values.medicalHistoryNumber) {
      errors.medicalHistoryNumber = localizationService.toLanguageString('validation.required');
    } else if (values.medicalHistoryNumber.length > 255) {
      errors.medicalHistoryNumber = localizationService.toLanguageString('studentCycle.validationNumberTooLong');
    }
    if (!values.date) {
      errors.date = localizationService.toLanguageString('validation.required');
    } else if (values.department) {
      const dateFrom = new Date(values.department.dateFrom);
      const dateTo = new Date(values.department.dateTo);
      if (values.date.getTime() < dateFrom.getTime() || values.date.getTime() > addDay(dateTo)) {
        errors.date = localizationService.toLanguageString('studentCycle.validationDateOutOfCycleRange');
      }
    }

    if (!modified.name && !modified.surname && !modified.email && !modified.stampNumber && !modified.department && !modified.manager && !modified.medicalHistoryNumber && !modified.date) {
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.surname) {
        errors.surname = validationResult?.errors?.surname;
      }
      if (validationResult?.errors?.email) {
        errors.email = validationResult?.errors?.email;
      }
      if (validationResult?.errors?.stampNumber) {
        errors.stampNumber = validationResult?.errors?.stampNumber;
      }
      if (validationResult?.errors?.departmentId) {
        errors.department = validationResult?.errors?.departmentId;
      }
      if (validationResult?.errors?.managerId) {
        errors.manager = validationResult?.errors?.managerId;
      }
      if (validationResult?.errors?.medicalHistoryNumber) {
        errors.medicalHistoryNumber = validationResult?.errors?.medicalHistoryNumber;
      }
      if (validationResult?.errors?.date) {
        errors.date = validationResult?.errors?.date;
      }
    }

    return errors;
  }

  handleAddDialogValidate(values, localizationService) {
    const errors = {};
    if (!values.studyPlanCycleStudent || !values.studyPlanCycleStudent?.id) {
      errors.studyPlanCycleStudent = localizationService.toLanguageString('validation.required');
    }
    switch (this.props.addDialogType) {
      case DialogTypes.ADD_SKILL:
        if (!values.skill || !values.skill?.id) {
          errors.skill = localizationService.toLanguageString('validation.required');
        }
        break;
      case DialogTypes.ADD_CASE:
        if (!values.case || !values.case?.id) {
          errors.case = localizationService.toLanguageString('validation.required');
        }
        break;
    }
    return errors;
  }

  handleToggleNewMentor(formRenderProps) {
    const showMentorFields = !formRenderProps.valueGetter('showMentorFields');
    if (showMentorFields) {
      formRenderProps.onChange('manager', { value: null });
    } else {
      formRenderProps.onChange('stampNumber', { value: '' });
      formRenderProps.onChange('name', { value: '' });
      formRenderProps.onChange('surname', { value: '' });
    }
    formRenderProps.onChange('showMentorFields', { value: showMentorFields });
  }

  async handleAddDialogCycleChange(event) {
    const payload = { value: event.value };
    switch (this.props.addDialogType) {
      case DialogTypes.ADD_SKILL:
        await this.props.loadAvailableCycleSkills(payload);
        break;
      case DialogTypes.ADD_CASE:
        await this.props.loadAvailableCycleCases(payload);
        break;
    }
  }

  async handleEditDialogSubmit(values, localizationService) {
    switch (this.props.editDialogType) {
      case DialogTypes.REGISTER_SKILL:
        await this.props.registerSkills({ ...values, localizationService, studentId: this.props.studentId });
        break;
      case DialogTypes.EDIT_SKILL:
        await this.props.saveSkill({ ...values, localizationService, studentId: this.props.studentId });
        break;
      case DialogTypes.REGISTER_CASE:
        await this.props.registerCases({ ...values, localizationService, studentId: this.props.studentId });
        break;
      case DialogTypes.EDIT_CASE:
        await this.props.saveCase({ ...values, localizationService, studentId: this.props.studentId });
        break;
    }
  }

  async handleDeleteConfirm(localizationService) {
    switch (this.props.deleteDialogType) {
      case DialogTypes.DELETE_SKILL:
        await this.props.deleteSelectedSkill({ localizationService, isAdditional: false, studentId: this.props.studentId });
        break;
      case DialogTypes.DELETE_ADDITIONAL_SKILL:
        await this.props.deleteSelectedSkill({ localizationService, isAdditional: true, studentId: this.props.studentId });
        break;
      case DialogTypes.DELETE_CASE:
        await this.props.deleteSelectedCase({ localizationService, isAdditional: false, studentId: this.props.studentId });
        break;
      case DialogTypes.DELETE_ADDITIONAL_CASE:
        await this.props.deleteSelectedCase({ localizationService, isAdditional: true, studentId: this.props.studentId });
        break;
      case DialogTypes.DELETE_CYCLE_SKILL:
        await this.props.deleteSelectedAdditionalCycleSkill({ localizationService, studentId: this.props.studentId });
        break;
      case DialogTypes.DELETE_CYCLE_CASE:
        await this.props.deleteSelectedAdditionalCycleCase({ localizationService, studentId: this.props.studentId });
        break;
    }
  }

  handlePreviewDepartmentDesignationClick(e) {
    this.props.navigate(`/study_plans/${this.props.cycle.studyPlanId}/cycles/${this.props.cycle.studyPlanCycleId}/students/${this.props.studyPlanCycleStudentId}/departments/${e.studyPlanCycleDepartmentStudentId}/designations/${e.designationId}/overview`);
  }

  handleRequestManagerApprovalClick(e) {
    this.props.submitApprovalRequest({
      studentId: this.props.studentId,
      studyPlanId: this.props.cycle.studyPlanId,
      studyPlanCycleId: this.props.cycle.studyPlanCycleId,
      studyPlanCycleStudentId: this.props.studyPlanCycleStudentId
    });
  }

  handleDeleteDialogClose() {
    if (!this.props.isDeleteDialogDeleting) {
      this.props.closeDeleteDialog();
    }
  }

  async handleAddDialogSubmit(values) {
    switch (this.props.addDialogType) {
      case DialogTypes.ADD_SKILL:
        await this.props.saveAdditionalCycleSkill({ ...values, studentId: this.props.studentId });
        break;
      case DialogTypes.ADD_CASE:
        await this.props.saveAdditionalCycleCase({ ...values, studentId: this.props.studentId });
        break;
    }
  }

  async promisedSetState(newState) {
    new Promise(resolve => this.setState(newState, resolve));
  };

  async handleEvaluateManagerClick(subjectUserId) {
    await this.promisedSetState({ ...this.state, shouldPreserveSelectedTab: true });
    this.props.navigate(`/my_studies/${this.props.studyPlanCycleStudentId}/surveys/${SURVEY_TYPES.MANAGER_EVALUATION_SURVEY}/manager/${subjectUserId}`);
  }

  async handleEvaluateCycleClick(departmentId) {
    await this.promisedSetState({ ...this.state, shouldPreserveSelectedTab: true });
    this.props.navigate(`/my_studies/${this.props.studyPlanCycleStudentId}/surveys/${SURVEY_TYPES.CYCLE_EVALUATION_SURVEY}/department/${departmentId}`);
  }

  async handlePreviewCycleManagerSurveyClick() {
    await this.promisedSetState({ ...this.state, shouldPreserveSelectedTab: true });
    this.props.navigate(`/my_studies/${this.props.studyPlanCycleStudentId}/surveys/${SURVEY_TYPES.RESIDENT_EVALUATION_SURVEY}/preview_survey/${this.props.cycleManagerSurvey?.id}`);
  }
}

registerForLocalization(StudentCycleOverview);
registerForIntl(StudentCycleOverview);

const mapStateToProps = state => ({
  loading: state.studentCycleOverview.loading,
  readOnly: state.studentCycleOverview.readOnly,
  studyPlanCycleStudentId: state.studentCycleOverview.studyPlanCycleStudentId,
  cycle: state.studentCycleOverview.cycle,
  cycleStatusHistory: state.studentCycleOverview.cycleStatusHistory,
  skills: state.studentCycleOverview.skills,
  additionalSkills: state.studentCycleOverview.additionalSkills,
  cases: state.studentCycleOverview.cases,
  additionalCases: state.studentCycleOverview.additionalCases,
  theoreticalParts: state.studentCycleOverview.theoreticalParts,
  isSummaryExpanded: state.studentCycleOverview.isSummaryExpanded,
  selectedTab: state.studentCycleOverview.selectedTab,

  studentSkillsLoading: state.studentCycleOverview.studentSkillsLoading,
  studentCasesLoading: state.studentCycleOverview.studentCasesLoading,
  studentTheoreticalPartsLoading: state.studentCycleOverview.studentTheoreticalPartsLoading,
  studentCycleCompletionLoading: state.studentCycleOverview.studentCycleCompletionLoading,

  availableDepartments: state.studentCycleOverview.availableDepartments,
  filteredAvailableDepartments: state.studentCycleOverview.filteredAvailableDepartments,

  competencies: state.studentCycleOverview.competencies,

  availableManagers: state.studentCycleOverview.availableManagers,

  isEditDialogVisible: state.studentCycleOverview.isEditDialogVisible,
  editDialogLoading: state.studentCycleOverview.editDialogLoading,
  editDialogTitle: state.studentCycleOverview.editDialogTitle,
  editDialogType: state.studentCycleOverview.editDialogType,
  editDialogConfirmButtonText: state.studentCycleOverview.editDialogConfirmButtonText,
  editDialogForm: state.studentCycleOverview.editDialogForm,
  editDialogValidationResult: state.studentCycleOverview.editDialogValidationResult,
  editDialogError: state.studentCycleOverview.editDialogError,

  isDeleteDialogVisible: state.studentCycleOverview.isDeleteDialogVisible,
  isDeleteDialogDeleting: state.studentCycleOverview.isDeleteDialogDeleting,
  deleteDialogConfirmationMessage: state.studentCycleOverview.deleteDialogConfirmationMessage,
  deleteDialogType: state.studentCycleOverview.deleteDialogType,

  isAddDialogVisible: state.studentCycleOverview.isAddDialogVisible,
  addDialogLoading: state.studentCycleOverview.addDialogLoading,
  addDialogType: state.studentCycleOverview.addDialogType,
  addDialogTitle: state.studentCycleOverview.addDialogTitle,
  addDialogErrorMessage: state.studentCycleOverview.addDialogErrorMessage,
  addDialogValidationResult: state.studentCycleOverview.addDialogValidationResult,
  addDialogForm: state.studentCycleOverview.addDialogForm,

  filteredAvailableCycles: state.studentCycleOverview.filteredAvailableCycles,
  availableCycleSkills: state.studentCycleOverview.availableCycleSkills,
  availableCycleSkillsLoading: state.studentCycleOverview.availableCycleSkillsLoading,
  availableCycleCases: state.studentCycleOverview.availableCycleCases,
  availableCycleCasesLoading: state.studentCycleOverview.availableCycleCasesLoading,

  managerApprovals: state.studentCycleOverview.managerApprovals,
  managerSurveys: state.studentCycleOverview.managerSurveys,
  cycleDepartmentSurveys: state.studentCycleOverview.cycleDepartmentSurveys,
  cycleManagerSurvey: state.studentCycleOverview.cycleManagerSurvey,
  isConfirmSubmitDialogVisible: state.studentCycleOverview.isConfirmSubmitDialogVisible,
  confirmSubmitDialogErrorMessage: state.studentCycleOverview.confirmSubmitDialogErrorMessage,
  skillsCompleted: state.studentCycleOverview.skillsCompleted,
  casesCompleted: state.studentCycleOverview.casesCompleted,
  isCycleStatusHistoryExpanded: state.studentCycleOverview.isCycleStatusHistoryExpanded,
  isRegisterTheoreticalPartDialogVisible: state.studentCycleOverview.isRegisterTheoreticalPartDialogVisible,
  selectedTheoreticalPart: state.studentCycleOverview.selectedTheoreticalPart,
  isConfirmApprovalRequestDialogVisible: state.studentCycleOverview.isConfirmApprovalRequestDialogVisible,
  confirmApprovalRquestDialogErrorMessage: state.studentCycleOverview.confirmApprovalRquestDialogErrorMessage,
  selectedManagerForApprovalRequestUser: state.studentCycleOverview.selectedManagerForApprovalRequestUser,
  isSubmitDepartmentDesignationDialogVisible: state.studentCycleOverview.isSubmitDepartmentDesignationDialogVisible
});

const mapDispatchToProps = dispatch => ({
  loadStudentCycleOverview: (payload) => dispatch(loadStudentCycleOverview(payload)),
  loadStudentCycleSkills: (payload) => dispatch(loadStudentCycleSkills(payload)),
  loadStudentCycleCases: (payload) => dispatch(loadStudentCycleCases(payload)),
  loadStudentCycleTheoreticalParts: (payload) => dispatch(loadStudentCycleTheoreticalParts(payload)),
  loadStudentCycleCompletion: (payload) => dispatch(loadStudentCycleCompletion(payload)),
  filterAvailableDepartments: (payload) => dispatch(filterAvailableDepartments(payload)),
  filterAvailableManagers: (payload) => dispatch(filterAvailableManagers(payload)),
  selectTab: (payload) => dispatch(selectTab(payload)),
  toggleStudentCycleOverviewSummaryExpanded: () => dispatch(toggleStudentCycleOverviewSummaryExpanded()),

  closeEditDialog: () => dispatch(closeEditDialog()),
  closeDeleteDialog: () => dispatch(closeDeleteDialog()),

  increaseSkills: (payload) => dispatch(increaseSkills(payload)),
  clearSkills: () => dispatch(clearSkills()),
  expandSkill: (payload) => dispatch(expandSkill(payload)),
  openSkillRegisterDialog: (payload) => dispatch(openSkillRegisterDialog(payload)),
  registerSkills: (payload) => dispatch(registerSkills(payload)),
  openSkillEditDialog: (payload) => dispatch(openSkillEditDialog(payload)),
  saveSkill: (payload) => dispatch(saveSkill(payload)),
  openSkillDeleteDialog: (payload) => dispatch(openSkillDeleteDialog(payload)),
  deleteSelectedSkill: (payload) => dispatch(deleteSelectedSkill(payload)),

  increaseCases: (payload) => dispatch(increaseCases(payload)),
  clearCases: () => dispatch(clearCases()),
  expandCase: (payload) => dispatch(expandCase(payload)),
  openCaseRegisterDialog: (payload) => dispatch(openCaseRegisterDialog(payload)),
  registerCases: (payload) => dispatch(registerCases(payload)),
  openCaseEditDialog: (payload) => dispatch(openCaseEditDialog(payload)),
  saveCase: (payload) => dispatch(saveCase(payload)),
  openCaseDeleteDialog: (payload) => dispatch(openCaseDeleteDialog(payload)),
  deleteSelectedCase: (payload) => dispatch(deleteSelectedCase(payload)),

  filterAvailableCycles: (payload) => dispatch(filterAvailableCycles(payload)),
  loadAvailableCycleSkills: (payload) => dispatch(loadAvailableCycleSkills(payload)),
  loadAvailableCycleCases: (payload) => dispatch(loadAvailableCycleCases(payload)),
  closeAddDialog: () => dispatch(closeAddDialog()),

  openAdditionalCycleSkillAddDialog: (payload) => dispatch(openAdditionalCycleSkillAddDialog(payload)),
  saveAdditionalCycleSkill: (payload) => dispatch(saveAdditionalCycleSkill(payload)),
  openAdditionalCycleSkillDeleteDialog: (payload) => dispatch(openAdditionalCycleSkillDeleteDialog(payload)),
  deleteSelectedAdditionalCycleSkill: (payload) => dispatch(deleteSelectedAdditionalCycleSkill(payload)),

  openAdditionalCycleCaseAddDialog: (payload) => dispatch(openAdditionalCycleCaseAddDialog(payload)),
  saveAdditionalCycleCase: (payload) => dispatch(saveAdditionalCycleCase(payload)),
  openAdditionalCycleCaseDeleteDialog: (payload) => dispatch(openAdditionalCycleCaseDeleteDialog(payload)),
  deleteSelectedAdditionalCycleCase: (payload) => dispatch(deleteSelectedAdditionalCycleCase(payload)),

  completeStudentCycleTheoreticalPart: (payload) => dispatch(completeStudentCycleTheoreticalPart(payload)),
  openRegisterTheoreticalPartDialog: (payload) => dispatch(openRegisterTheoreticalPartDialog(payload)),
  closeRegisterTheoreticalPartDialog: () => dispatch(closeRegisterTheoreticalPartDialog()),

  openCycleConfirmSubmitDialog: () => dispatch(openCycleConfirmSubmitDialog()),
  closeCycleConfirmSubmitDialog: () => dispatch(closeCycleConfirmSubmitDialog()),
  submitCycle: (payload) => dispatch(submitCycle(payload)),
  expandCycleStatusHistory: () => dispatch(expandCycleStatusHistory()),
  closeCycleStatusHistory: () => dispatch(closeCycleStatusHistory()),
  clearStudentCycleOverview: (payload) => dispatch(clearStudentCycleOverview(payload)),
  openConfirmApprovalRequestDialog: (payload) => dispatch(openConfirmApprovalRequestDialog(payload)),
  closeConfirmApprovalRequestDialog: () => dispatch(closeConfirmApprovalRequestDialog()),
  submitApprovalRequest: (payload) => dispatch(submitApprovalRequest(payload)),

  submitStudyPlanCycleDepartmentStudentDesignation: () => dispatch(submitStudyPlanCycleDepartmentStudentDesignation()),
  openSubmitDepartmentDesignationDialog: (payload) => dispatch(openSubmitDepartmentDesignationDialog(payload)),
  closeSubmitDepartmentDesignationDialog: () => dispatch(closeSubmitDepartmentDesignationDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudentCycleOverview));