import CycleFormPageActionTypes from '../actions/cycle-form-page-action-types';

const initialState = {
  loading: false,
  cycle: null,
  saveSuccess: false,
  validationResult: null,
  targetCycleId: null,

  availableStudyPrograms: null,
  availableStudyProgramsLoading: false,
  availableStudyProgramsCancelToken: null,

  availableAdministrators: null,
  availableAdministratorsLoading: false,
  availableAdministratorsCancelToken: null,

  cycleChildren: [],
  cancellationTokenSources: new Map()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_START:
      return {
        ...state,
        loading: true,
        validationResult: null
      };
    case CycleFormPageActionTypes.LOAD_CYCLE_FORM_PAGE_END:
      return {
        ...state,
        loading: false,
        targetCycleId: action.targetCycleId,
        cycle: action.payload ? action.payload.cycle : null,
        validationResult: action.payload ? action.payload.validationResult : null
      };
    case CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_STUDY_PROGRAMS_START:
      return {
        ...state,
        availableStudyProgramsLoading: true
      };
    case CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_STUDY_PROGRAMS_END:
      return {
        ...state,
        availableStudyProgramsLoading: false,
        availableStudyPrograms: action.payload ? action.payload.studyPrograms : null
      };
    case CycleFormPageActionTypes.LOAD_CYCLE_CHILDREN_AVAILABLE_STUDY_PROGRAM_START:
      return {
        ...state,
        cycleChildren: action.payload?.cycleChildren ?? []
      };
    case CycleFormPageActionTypes.LOAD_CYCLE_CHILDREN_AVAILABLE_STUDY_PROGRAM_END:
      return {
        ...state,
        cycleChildren: action.payload?.cycleChildren ?? []
      };
    case CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_ADMINISTRATORS_START:
      return {
        ...state,
        availableAdministratorsLoading: true
      };
    case CycleFormPageActionTypes.SEARCH_CYCLE_FORM_PAGE_ADMINISTRATORS_END:
      return {
        ...state,
        availableAdministratorsLoading: false,
        availableAdministrators: action.payload ? action.payload.availableAdministrators : null
      };
    default:
      return state;
  }
};