import { MyNotificationsListPageActionTypes } from '.';

export const loadMyNotificationsListPage = (payload) => async(dispatch) => {
  dispatch(loadMyNotifications(payload));
};

export const loadMyNotifications = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: MyNotificationsListPageActionTypes.LOAD_MY_NOTIFICATIONS_LIST_PAGE_NOTIFICATIONS_START });
  const { skip, limit } = payload;
  const state = getState().myNotificationsListPage;
  const query = {
    skip,
    limit
  };
  try {
    const response = await api.get('api/users/current/notifications', query);
    let notifications = [];
    let readNotificationIds = [];
    if (response?.data?.length > 0) {
      const responseReadNotificationIds = response.data?.filter(item => item.userReadOn != null).map(item => item.id);
      if (skip == 0) {
        notifications = response.data;
        readNotificationIds = responseReadNotificationIds;
      } else {
        notifications = state.notifications.concat(response.data);
        readNotificationIds = state.readNotificationIds.concat(responseReadNotificationIds);
      }
    }
    dispatch({
      type: MyNotificationsListPageActionTypes.LOAD_MY_NOTIFICATIONS_LIST_PAGE_NOTIFICATIONS_END,
      payload: {
        notifications,
        readNotificationIds,
        totalNotificationsCount: response?.total ?? 0
      }
    });
  } catch (error) {
    throw error;
  }
};

export const updateNotificationRead = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().myNotificationsListPage;
  const { notificationId } = payload;
  if (!state.readNotificationIds?.includes(notificationId)) {
    try {
      await api.post(`api/users/current/notifications/${notificationId}/read`, null);
      dispatch({
        type: MyNotificationsListPageActionTypes.UPDATE_MY_NOTIFICATIONS_LIST_PAGE_READ_NOTIFICATION_IDS,
        payload: {
          readNotificationIds: [...state.readNotificationIds, notificationId]
        }
      });
    } catch (error) {
      throw error;
    }
  }
};