import CyclePlanListPageActionTypes from './cycle-plan-list-page-action-types';
import axios from 'axios';
import { USER_TYPES } from '../../../resources/userTypes';

export const loadCyclePlanListPage = payload => async(dispatch, getState) => {
  const state = getState().cyclePlanListPage;
  dispatch(findCyclePlans(payload));
  if (!state.filterDepartments) {
    dispatch(searchCyclePlansFilterDepartments());
  }
  if (!state.filterResidents) {
    dispatch(searchCyclePlansFilterResidents());
  }
  if (!state.filterStudyPrograms) {
    dispatch(searchCyclePlansFilterStudyPrograms());
  }
  if (!state.filterCycles) {
    dispatch(searchCyclePlansFilterCycles());
  }
};

export const findCyclePlans = payload => async(dispatch, getState, { api }) => {
  dispatch({ type: CyclePlanListPageActionTypes.LOAD_CYCLE_PLAN_LIST_START });
  const state = getState().cyclePlanListPage;
  const year = state?.filter?.year ?? new Date().getFullYear();
  const query = {
    keyword: state.filter?.keyword,
    departmentId: state.filter?.department?.id,
    studentUserId: state.filter?.resident?.id,
    studyProgramId: state.filter?.studyProgram?.id,
    cycleId: state.filter?.cycle?.id
  };
  try {
    state.cyclePlansCancelToken?.cancel();
    state.cyclePlansCancelToken = axios.CancelToken.source();
    const cyclePlans = await api.get(`api/cycle_plans/${year}`, query, state.cyclePlansCancelToken.token);
    const lowerCutoffDate = new Date(year, 7, 1);
    const upperCutoffDate = new Date(year + 1, 6, 31);
    cyclePlans?.data?.forEach(element => {
      element.events.forEach(event => {
        const startDate = new Date(event.startDate);
        event.transformedStartDate = startDate < lowerCutoffDate
          ? lowerCutoffDate
          : startDate;
        const endDate = new Date(event.endDate);
        event.transformedEndDate = endDate > upperCutoffDate
          ? upperCutoffDate
          : endDate;
      });
      element.intersectingEvents.forEach(intersectingEvent => {
        const startDate = new Date(intersectingEvent.startDate);
        intersectingEvent.transformedStartDate = startDate < lowerCutoffDate
          ? lowerCutoffDate
          : startDate;
        const endDate = new Date(intersectingEvent.endDate);
        intersectingEvent.transformedEndDate = endDate > upperCutoffDate
          ? upperCutoffDate
          : endDate;
      });
    });
    dispatch({
      type: CyclePlanListPageActionTypes.LOAD_CYCLE_PLAN_LIST_END,
      payload: {
        cyclePlans: cyclePlans ? cyclePlans.data : [],
        total: cyclePlans ? cyclePlans.total : 0,
        skip: payload ? payload.skip : state.skip,
        take: payload ? payload.take : state.take,
        year: year
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CyclePlanListPageActionTypes.LOAD_CYCLE_PLAN_LIST_END });
      throw error;
    }
  }
};

export const clearCyclePlansFilter = () => dispatch => {
  dispatch({
    type: CyclePlanListPageActionTypes.FILTER_CYCLE_PLAN_LIST,
    payload: {}
  });
  dispatch(findCyclePlans());
};

export const filterCyclePlans = payload => dispatch => {
  dispatch({
    type: CyclePlanListPageActionTypes.FILTER_CYCLE_PLAN_LIST,
    payload: payload
  });
  dispatch(findCyclePlans());
};

export const searchCyclePlansFilterDepartments = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().cyclePlanListPage;
  try {
    state.filterDepartmentsCancelToken?.cancel();
    state.filterDepartmentsCancelToken = axios.CancelToken.source();
    const departments = await api.get('api/departments', query, state.filterDepartmentsCancelToken.token);
    dispatch({
      type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_END,
      payload: {
        departments: departments ? departments.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_DEPARTMENT_END });
      throw error;
    }
  }
};

export const searchCyclePlansFilterResidents = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_START });
  const state = getState().cyclePlanListPage;
  try {
    state.filterResidentsCancelToken?.cancel();
    state.filterResidentsCancelToken = axios.CancelToken.source();
    const users = await api.get(`api/users_search`, { keyword: payload?.keyword, type: USER_TYPES.STUDENT }, state.filterResidentsCancelToken.token);
    dispatch({
      type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_END,
      payload: {
        residents: users?.data?.map(element => (
          {
            id: element.id,
            fullName: element.name + ' ' + element.surname
          }
        ))
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_RESIDENT_END });
      throw error;
    }
  }
};

export const searchCyclePlansFilterStudyPrograms = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_START });
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  const state = getState().cyclePlanListPage;
  try {
    state.filterStudyProgramsCancelToken?.cancel();
    state.filterStudyProgramsCancelToken = axios.CancelToken.source();
    const studyPrograms = await api.get('api/study_programs', query, state.filterStudyProgramsCancelToken.token);
    dispatch({
      type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_END,
      payload: {
        studyPrograms: studyPrograms ? studyPrograms.data : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_STUDY_PROGRAM_END });
      throw error;
    }
  }
};

export const searchCyclePlansFilterCycles = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_START });
  const state = getState().cyclePlanListPage;
  const query = {
    skip: 0,
    limit: 50,
    keyword: payload?.keyword
  };
  try {
    state.filterCyclesCancelToken?.cancel();
    state.filterCyclesCancelToken = axios.CancelToken.source();
    const cycles = await api.get('api/cycles', query, state.filterCyclesCancelToken.token);
    dispatch({
      type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_END,
      payload: {
        cycles: cycles
          ? cycles.data?.map((cycleA, cycleAIndex, array) => {
            const shouldIncludeCode = array.find((cycleB, cycleBIndex) => cycleAIndex !== cycleBIndex && cycleA.name === cycleB.name);
            const cycleCode = shouldIncludeCode ? `, (${cycleA.code})` : '';
            return {
              id: cycleA.id,
              name: `${cycleA.name}${cycleCode}`
            };
          })
          : []
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: CyclePlanListPageActionTypes.SEARCH_CYCLE_PLAN_LIST_FILTER_CYCLE_END });
      throw error;
    }
  }
};