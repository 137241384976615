import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, Text, WrapLayout } from '../../../ui';
import { ValueItem, DateFormatter, InlineBadge } from '../../shared/components';
import {
  approveDesignation,
  loadDesignationOverviewPage,
  cleanupDesignationOverviewPage
} from '../actions/designation-overview-page-actions';
import { withRouter } from '../../../components/withRouter';
import { DatePeriodFormatter } from '../../shared/components/Formatter';
import { getDesignationStatusColor } from '../../../utils/designationStatusColors';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { DESIGNATION_STATUS } from '../../../resources/designationStatus';
import colors from '../../../ui/components/shared/colors';

const ActionButton = styled(Button)`
    width: 128px;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 8px;
  column-gap: 8px;
`;

class DesignationOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleApproveDesignation = this.handleApproveDesignation.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.designationId) {
      this.props.loadDesignationOverviewPage({ designationId: this.props.params.designationId });
    }
  }

  componentWillUnmount() {
    if (this.props.designation?.designationPdfObjectUrl) {
      this.props.cleanupDesignationOverviewPage();
    }
  }

  render() {
    const { designation, loading } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${designation?.department}`
        }
        breadcrumbItems={[
          {
            id: 'designations',
            text: localizationService.toLanguageString('designations.designations')
          },
          {
            id: 'designation',
            text: localizationService.toLanguageString('designations.designation'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        headerActions={
          <>
            {this.props.cycleAdministratorOverview && designation?.status === DESIGNATION_STATUS.SUBMITTED &&
              <HeaderButtonContainer>
                <ActionButton
                  type='button'
                  themeColor='primary'
                  onClick={() => this.handleApproveDesignation()}
                >
                  {localizationService.toLanguageString('buttons.confirm')}
                </ActionButton>
              </HeaderButtonContainer>
            }
          </>
        }
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('designations.date')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.accreditationDateFrom && designation?.accreditationDateTo ? DatePeriodFormatter(designation.accreditationDateFrom, designation.accreditationDateTo, intlService) : ''}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.cycle')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.cycle}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.credits')}
              labelWidth='100px'
              loading={loading}
            >
              {designation?.accreditationCredits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('designations.statusHistory')}
              labelWidth='100px'
              loading={loading}
            >
              <WrapLayout orientation='vertical'>
                {designation?.designationHistory?.map((element, index) => {
                  return (
                    <div key={index}>
                      <InlineBadge themeColor={getDesignationStatusColor(element.status)}>
                        {localizationService.toLanguageString('designationStatus.' + element.status)}
                      </InlineBadge>
                      <Text variant={'body2'} textColor={colors.GRAY_80}>
                        {` ${element.createdBy} ${DateFormatter(element.date, intlService)}`}
                      </Text>
                    </div>
                  );
                })}
              </WrapLayout>
            </ValueItem>
          </StackLayout>
        }
      >
        {designation?.designationPdfObjectUrl &&
          <iframe
            src={designation?.designationPdfObjectUrl}
            title={localizationService.toLanguageString('designations.designation')}
            width={'100%'}
            height={'1000'}
          />
        }
      </OverviewPage>
    );
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'designations') {
      this.props.navigate('/designations');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  handleApproveDesignation() {
    this.props.approveDesignation({ designationId: this.props.params.designationId });
  }
}

registerForLocalization(DesignationOverviewPage);
registerForIntl(DesignationOverviewPage);

const mapStateToProps = state => ({
  designation: state.designationOverviewPage.designation,
  loading: state.designationOverviewPage.loading
});

const mapDispatchToProps = dispatch => ({
  loadDesignationOverviewPage: (payload) => dispatch(loadDesignationOverviewPage(payload)),
  approveDesignation: (payload) => dispatch(approveDesignation(payload)),
  cleanupDesignationOverviewPage: () => dispatch(cleanupDesignationOverviewPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesignationOverviewPage));
