import React from 'react';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { withRouter } from '../../../components/withRouter';
import { loadMySurveyFormPage, saveMySurvey } from '../actions/my-survey-form-page-actions';
import { FormPage, StackLayout, WrapLayout, Text, Colors, Rating, TextArea } from '../../../ui';
import { DecimalFormatter, EmptyFormatter, ValueItem } from '../../shared/components';
import { SURVEY_QUESTION_TYPES } from '../../../resources/surveyQuestionType';
import styled from 'styled-components';
import { Field } from '@progress/kendo-react-form';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { SURVEY_TYPES } from '../../../resources/surveyType';
import { RATING_MODES } from '../../../ui/components/inputs/rating';
import { toRomanNumeral } from '../../../utils';

const Container = styled(WrapLayout)`
  display: grid;
`;

const StyledRatingField = styled(Field)`
  margin-bottom: 0.75rem;
`;

const StyledTextAreaField = styled(StyledRatingField)`
  margin-left: 4px;
`;

const QuestionNoteText = styled(Text).attrs({
})`
  margin-bottom: 4px;
  margin-left: 4px;
`;

const Seperator = styled.div`
  height: 24px;
`;

const ForbiddenContainer = styled.div`
  padding: 16px;
`;

const Message = styled.div`
  margin-top: 16px;
`;

class MySurveyFormPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleFormValues = this.handleFormValues.bind(this);
    this.renderSurveyQuestions = this.renderSurveyQuestions.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.renderEvaluationsDescription = this.renderEvaluationsDescription.bind(this);
    this.state = {
      evaluationAverage: 0,
      surveyQuestionLabels: []
    };
  }

  async componentDidMount() {
    if (this.props.params?.surveyId) {
      await this.props.loadMySurveyFormPage({
        surveyId: this.props.params.surveyId,
        competencySurveyStudentId: this.props.params.competencySurveyStudentId
      });
      if (this.props.mySurvey?.surveyAnswerStudentId > 0 && !this.props.preview) {
        this.props.navigate(`${location.pathname}/preview`);
      }
    }
  }

  render() {
    const { mySurvey, loading, validationResult } = this.props;
    const localizationService = provideLocalizationService(this);
    const areAllQuestionsText = mySurvey?.mySurveyQuestions?.every(mySurveyQuestion => mySurveyQuestion.type === SURVEY_QUESTION_TYPES.TEXT);
    return ((this.props?.myUserId == mySurvey?.managerId || this.props.preview) ?
      <FormPage
        title={mySurvey?.code ? `${mySurvey.code} ${mySurvey.name}` : mySurvey?.name}
        breadcrumbItems={this.getBreadcrumbItems()}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        renderBackButton={true}
        onBackClick={this.handleBackClick}
        validationResult={validationResult}
        loading={loading}
        localizationService={localizationService}
        initialValues={mySurvey}
        onSubmit={this.handleSubmit}
        submitButtonLabel={localizationService.toLanguageString('buttons.evaluate')}
        onCancel={this.handleCancel}
        cancelButtonLabel={this.props.preview ? localizationService.toLanguageString('buttons.close') : localizationService.toLanguageString('buttons.cancel')}
        validator={this.handleValidate}
        formValueHandler={this.handleFormValues}
        hideSaveButton={this.props.preview}
        render={() => (
          <StackLayout>
            {!this.props.preview && (
              <>
                <ValueItem
                  label={localizationService.toLanguageString('mySurvey.tieredCompetency')}
                  labelWidth='150px'
                  loading={loading}
                  truncateValue={false}
                >
                  {`${mySurvey.competencyAbbreviation} ${mySurvey.competencyName}`}
                </ValueItem>
                <ValueItem
                  label={localizationService.toLanguageString('mySurvey.resident')}
                  labelWidth='150px'
                  loading={loading}
                >
                  {EmptyFormatter(mySurvey?.student)}
                </ValueItem>
                <ValueItem
                  label={localizationService.toLanguageString('mySurvey.comment')}
                  labelWidth='150px'
                  loading={loading}
                >
                  {EmptyFormatter(mySurvey?.comment)}
                </ValueItem>
              </>
            )}
            {this.props.preview &&
              <>
                <ValueItem
                  label={localizationService.toLanguageString('mySurvey.evaluation')}
                  labelWidth='55px'
                  loading={loading}
                >
                  {`${EmptyFormatter(DecimalFormatter(mySurvey?.evaluation, 2))}`}
                </ValueItem>
                <Seperator/>
              </>
            }
            {areAllQuestionsText ||
              <Tooltip anchorElement='target' position='top' content={(e) => this.renderTooltip(e, mySurvey?.surveyType)}>
                <Text variant='caption' textColor={Colors.GRAY_50}>
                  {`${localizationService.toLanguageString('mySurvey.evaluationsDescription')} `}<span className='k-icon k-i-info' title='true'></span>
                </Text>
              </Tooltip>
            }
            {mySurvey?.mySurveyQuestions?.map((element, index) => this.renderSurveyQuestions(element, index))}
            {(areAllQuestionsText || !this.props.preview) &&
              <ValueItem
                label={localizationService.toLanguageString('mySurvey.evaluation')}
                labelWidth='55px'
                loading={loading}
              >
                {`${DecimalFormatter(this.state.evaluationAverage, 0)} (${DecimalFormatter(this.state.evaluationAverage)})`}
              </ValueItem>
            }
          </StackLayout>
        )}
      />
      :
      <ForbiddenContainer>
        <h2>{localizationService.toLanguageString('authorization.forbiddenMessage')}</h2>
        <Message>{localizationService.toLanguageString('authorization.forbiddenTitle')}</Message>
      </ForbiddenContainer>
    );
  }

  renderEvaluationsDescription(surveyType) {
    const localizationService = provideLocalizationService(this);
    const evaluationDescriptions = [];

    if (surveyType === SURVEY_TYPES.SURVEY_360) {
      for (let index = 0; index <= 5; index++) {
        evaluationDescriptions.push(`${index} - ${localizationService.toLanguageString(`mySurvey.survey360evaluation${index}Description`)}`);
      }
    } else {
      for (let index = 0; index <= 5; index++) {
        evaluationDescriptions.push(`${toRomanNumeral(index)} - ${localizationService.toLanguageString(`mySurvey.evaluation${index}Description`)}`);
      }
    }

    return evaluationDescriptions;
  }

  renderTooltip(props, surveyType) {
    return (
      <StackLayout rowGap='0'>
        {this.renderEvaluationsDescription(surveyType).map((element, index) => (
          <div key={index}>
            {element}
          </div>
        ))}
      </StackLayout>
    );
  }

  renderSurveyQuestions(e, index) {
    const fieldName = 'mySurveyAnswers[' + e.number + ']';
    const ratingMode = this.props.mySurvey?.surveyType !== SURVEY_TYPES.SURVEY_360 ? RATING_MODES.ROMAN : null;
    return (
      <Container key={index} orientation='vertical' rowGap='0'>
        <StackLayout orientation='vertical' rowGap='0'>
          <Text variant='subtitle2' textColor={Colors.GRAY_80}>
            {`${e.number}. ${e.questionText}`}
          </Text>
        </StackLayout>
        {e.type === SURVEY_QUESTION_TYPES.NUMERIC_ZERO_THROUGH_FIVE &&
          <>
            <StyledRatingField
              name={fieldName}
              component={Rating}
              min={0}
              max={5}
              disabled={this.props.preview}
              mode={ratingMode}
            />
            <Text variant='caption' textColor={Colors.GRAY_50}>
              {this.state.surveyQuestionLabels[index]}
            </Text>
          </>
        }
        {e.type === SURVEY_QUESTION_TYPES.NUMERIC_ONE_THROUGH_FIVE &&
          <>
            <StyledRatingField
              name={fieldName}
              component={Rating}
              min={1}
              max={5}
              disabled={this.props.preview}
              mode={ratingMode}
            />
            <Text variant='caption' textColor={Colors.GRAY_50}>
              {this.state.surveyQuestionLabels[index]}
            </Text>
          </>
        }
        {e.type === SURVEY_QUESTION_TYPES.NUMERIC_ONE_THROUGH_TEN &&
          <StyledRatingField
            name={fieldName}
            component={Rating}
            disabled={this.props.preview}
          />
        }
        {e.type === SURVEY_QUESTION_TYPES.TEXT &&
          <StyledTextAreaField
            name={fieldName}
            component={TextArea}
            autoSize={true}
            width='300px'
            disabled={this.props.preview}
          />
        }
        {e.questionNote &&
          <QuestionNoteText variant='caption' width='320px' textColor={Colors.GRAY_50}>
            {e.questionNote}
          </QuestionNoteText>
        }
      </Container>
    );
  }

  handleFormValues(values) {
    const localizationService = provideLocalizationService(this);
    let surveyQuestionLabels = [];
    let sum = 0;
    let count = 0;
    values?.mySurveyAnswers?.map((element, index) => {
      if (typeof element === 'number') {

        if (this.props.mySurvey?.surveyType === SURVEY_TYPES.SURVEY_360) {
          surveyQuestionLabels[index - 1] = `${localizationService.toLanguageString(`mySurvey.survey360evaluation${element}Description`)}`;
        } else {
          surveyQuestionLabels[index - 1] = `${localizationService.toLanguageString(`mySurvey.evaluation${element}Description`)}`;
        }

        if (element > 0) {
          sum += element;
          count++;
        }
      }
    });
    this.setState({ evaluationAverage: sum / count >= 0 ? sum / count : 0, surveyQuestionLabels });
  }

  handleValidate(values, localizationService) {
    let errors = {};
    for (let i = 0; i < values?.mySurveyQuestions?.length; i++) {
      if (values.mySurveyQuestions[i].type !== SURVEY_QUESTION_TYPES.TEXT && values.mySurveyAnswers[values.mySurveyQuestions[i].number] == null) {
        errors = { ...errors, ['mySurveyAnswers[' + values.mySurveyQuestions[i].number + ']']: localizationService.toLanguageString('validation.required') };
      }
    }
    return errors;
  }

  handleSubmit(payload) {
    this.props.saveMySurvey({ ...payload, competencySurveyStudentId: this.props.params.competencySurveyStudentId });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'mySurveys') {
      this.props.navigate('/my_surveys');
    }
    if (e.id === 'myCompetencies') {
      this.props.navigate('/my_competencies');
    }
    if (e.id === 'myCompetency') {
      this.props.navigate(`/my_competencies/${this.props.params.competencyId}/overview`);
    }
    if (e.id === 'myCompetencySurvey') {
      this.props.navigate(`/my_competencies/${this.props.params.competencyId}/surveys/${this.props.mySurvey.competencySurveyId}/overview`);
    }
  }

  handleCancel() {
    this.props.navigate('/my_surveys');
  }

  handleBackClick() {
    this.props.navigate(-1);
  }

  getBreadcrumbItems() {
    const localizationService = provideLocalizationService(this);
    if (this.props.myCompetency) {
      return [
        {
          id: 'myCompetencies',
          text: localizationService.toLanguageString('studentCompetency.myCompetencies')
        },
        {
          id: 'myCompetency',
          text: localizationService.toLanguageString('studentCompetency.myCompetency')
        },
        {
          id: 'myCompetencySurvey',
          text: localizationService.toLanguageString('studentCompetency.surveys')
        },
        {
          id: 'myCompetencySurveyEvaluation',
          text: localizationService.toLanguageString('studentCompetency.survey'),
          disabled: true
        }
      ];
    }

    return [
      {
        id: 'mySurveys',
        text: localizationService.toLanguageString('mySurvey.mySurveys')
      },
      {
        id: 'mySurvey',
        text: localizationService.toLanguageString('mySurvey.mySurvey'),
        disabled: true
      }
    ];
  }
}

const mapStateToProps = state => ({
  loading: state.mySurveyFormPage.loading,
  mySurvey: state.mySurveyFormPage.mySurvey,
  validationResult: state.mySurveyFormPage.validationResult,
  myUserId: state.app.userId
});

const mapDispatchToProps = dispatch => ({
  loadMySurveyFormPage: (payload) => dispatch(loadMySurveyFormPage(payload)),
  saveMySurvey: (payload) => dispatch(saveMySurvey(payload))
});

registerForLocalization(MySurveyFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MySurveyFormPage));