import { DESIGNATION_STATUS } from '../resources/designationStatus';

export const getDesignationStatusColor = (status) => {
  switch (status) {
    case DESIGNATION_STATUS.NOT_SUBMITTED:
      return 'inverse';
    case DESIGNATION_STATUS.SUBMITTED:
      return 'primary';
    case DESIGNATION_STATUS.APPROVED:
      return 'info';
    case DESIGNATION_STATUS.SIGNED:
      return 'success';
    default:
      return 'inverse';
  }
};