import React from 'react';
import { ListPage } from '../../../ui';
import { DesignationList, DesignationListFilter } from './index';

class DesignationListPage extends React.PureComponent {
  render() {
    return (
      <ListPage filter={<DesignationListFilter/>}>
        <DesignationList/>
      </ListPage>
    );
  }
}

export default DesignationListPage;