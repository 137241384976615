import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { ValueItem, DateFormatter } from '../../shared/components';
import { loadDepartmentOverviewPage } from '../actions/department-overview-page-actions';
import { withRouter } from '../../../components/withRouter';

class DepartmentOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.departmentId) {
      this.props.loadDepartmentOverviewPage(this.props.params.departmentId);
    }
  }

  render() {
    const { department, loading, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${department?.name}`
        }
        headerActions={
          <>
            {canWrite &&
              <Button
                id='edit-button'
                type='button'
                themeColor='primary'
                onClick={this.handleEditClick}
              >
                {localizationService.toLanguageString('custom.edit')}
              </Button>
            }
          </>
        }
        breadcrumbItems={[
          {
            id: 'departments',
            text: localizationService.toLanguageString('department.departments')
          },
          {
            id: 'department',
            text: localizationService.toLanguageString('department.department'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('department.code')}
              labelWidth='115px'
              loading={loading}
            >
              {department?.code}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.address')}
              labelWidth='115px'
              loading={loading}
            >
              {department?.address}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.manager')}
              labelWidth='115px'
              loading={loading}
            >
              {department?.managerName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.phone')}
              labelWidth='115px'
              loading={loading}
            >
              {department?.managerPhone}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.email')}
              labelWidth='115px'
              loading={loading}
            >
              {department?.managerEmail}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.accreditationFrom')}
              labelWidth='115px'
              loading={loading}
            >
              {DateFormatter(department?.accreditationFrom, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.accreditationTo')}
              labelWidth='115px'
              loading={loading}
            >
              {DateFormatter(department?.accreditationTo, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.isForeign')}
              labelWidth='115px'
              loading={loading}
            >
              {localizationService.toLanguageString(`common.${department?.isForeign ? 'yes' : 'no'}`)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('department.isExternal')}
              labelWidth='115px'
              loading={loading}
            >
              {localizationService.toLanguageString(`common.${department?.isExternal ? 'yes' : 'no'}`)}
            </ValueItem>
          </StackLayout>
        }
      >
      </OverviewPage>
    );
  }

  handleEditClick() {
    this.props.navigate(`/departments/${this.props.params.departmentId}`);
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'departments') {
      this.props.navigate('/departments');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(DepartmentOverviewPage);
registerForIntl(DepartmentOverviewPage);

const mapStateToProps = state => ({
  department: state.departmentOverviewPage.department,
  loading: state.departmentOverviewPage.loading,
  canWrite: state.departmentOverviewPage.canWrite
});

const mapDispatchToProps = dispatch => ({
  loadDepartmentOverviewPage: (payload) => dispatch(loadDepartmentOverviewPage(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DepartmentOverviewPage));
