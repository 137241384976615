import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field, FieldArray } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { FormPage, TextBox, WrapLayout, NumericBox, DatePicker, ComboBox, StackLayout, Text } from '../../../ui';
import {
  loadStudyProgramFormPage,
  saveStudyProgram,
  loadAvailableCoordinators,
  loadAvailableAdministrators,
  loadAvailableCommitteeMembers,
  cancelStudyProgramFormPage,
  loadInputData,
  loadAvailableDivisions,
  loadAvailableFaculties
} from '../actions/study-program-form-page-actions';
import { openUserSearchDialog } from '../../users/actions/user-search-dialog-actions';
import { withRouter } from '../../../components/withRouter';
import studyProgramFieldArrayNames from '../actions/study-program-field-array-names';

const ProgramUsersContainer = styled(WrapLayout).attrs(props => ({
  orientation: 'vertical',
  rowgap: '2px',
  width: '35px'
}))`
  margin-top: 2px;
`;

const ProgramUsersTitle = styled(Text).attrs(props => ({
  variant: 'h6',
  textColor: props.theme.gray80,
  truncate: true
}))`
  margin-top: 20px;
`;

const DeleteButton = styled(Button).attrs(props => ({
  type: 'button',
  icon: 'delete'
}))`
  margin-top: 28px;
`;

class StudyProgramFormPage extends React.PureComponent {

  constructor() {
    super();
    this.handleValidate = this.handleValidate.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.renderCoordinators = this.renderCoordinators.bind(this);
    this.renderAdministrators = this.renderAdministrators.bind(this);
    this.renderCommitteeMembers = this.renderCommitteeMembers.bind(this);
    this.handleOpenUserSearchDialog = this.handleOpenUserSearchDialog.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyProgramFormPage({
      studyProgramId: this.props.params.studyProgramId
    });
  }

  componentWillUnmount() {
    this.props.cancelStudyProgramFormPage();
  }

  render() {
    const localizationService = provideLocalizationService(this);
    return (
      <FormPage
        title={localizationService.toLanguageString('studyProgram.formTitle')}
        breadcrumbItems={[
          {
            id: 'study-programs',
            text: localizationService.toLanguageString('studyProgram.studyPrograms')
          },
          {
            id: 'study-program',
            text: localizationService.toLanguageString('studyProgram.studyProgram'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        validationResult={this.props.validationResult}
        loading={this.props.loading}
        initialValues={this.props.studyProgram}
        localizationService={localizationService}
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        validator={this.handleValidate}
        render={(formRenderProps) => (
          <>
            <Field
              name='name'
              label={localizationService.toLanguageString('studyProgram.name')}
              component={TextBox}
              width='320px'
            />
            <WrapLayout>
              <Field
                name='credits'
                label={localizationService.toLanguageString('studyProgram.credits')}
                component={NumericBox}
                width='155px'
              />
              <Field
                name='years'
                label={localizationService.toLanguageString('studyProgram.years')}
                component={NumericBox}
                width='155px'
              />
            </WrapLayout>
            <Field
              name='code'
              label={localizationService.toLanguageString('studyProgram.code')}
              component={TextBox}
              width='320px'
            />
            <WrapLayout width='320px'>
              <Field
                name='validFrom'
                label={localizationService.toLanguageString('studyProgram.validFrom')}
                component={DatePicker}
                width='155px'
              />
              <Field
                name='validTo'
                label={localizationService.toLanguageString('studyProgram.validTo')}
                component={DatePicker}
                width='155px'
              />
            </WrapLayout>
            <Field
              name='division'
              label={localizationService.toLanguageString('studyProgram.division')}
              component={ComboBox}
              loading={this.props.availableDivisionsLoading}
              data={this.props.availableDivisions}
              onFilter={this.props.loadAvailableDivisions}
              textField='name'
              valueField='id'
              width='320px'
            />
            <Field
              name='faculty'
              label={localizationService.toLanguageString('studyProgram.faculty')}
              component={ComboBox}
              loading={this.props.availableFacultiesLoading}
              data={this.props.availableFaculties}
              onFilter={this.props.loadAvailableFaculties}
              textField='name'
              valueField='id'
              width='320px'
            />
            <Field
              name='descriptionUrl'
              label={localizationService.toLanguageString('studyProgram.descriptionLink')}
              component={TextBox}
              width='320px'
            />
            <FieldArray
              name={studyProgramFieldArrayNames.COORDINATORS}
              component={this.renderCoordinators}
            />
            <FieldArray
              name={studyProgramFieldArrayNames.ADMINISTRATORS}
              component={this.renderAdministrators}
            />
            <FieldArray
              name={studyProgramFieldArrayNames.COMMITTEE_MEMBERS}
              component={this.renderCommitteeMembers}
            />
          </>
        )}
      />
    );
  }

  renderCoordinators(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <ProgramUsersContainer>
        <ProgramUsersTitle>{localizationService.toLanguageString('studyProgram.coordinators')}</ProgramUsersTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = studyProgramFieldArrayNames.COORDINATORS + '[' + index + ']';
          const coordinator = this.props.coordinators[index];
          return (
            <StackLayout key={index} orientation='horizontal' align='left' valign='top'>
              <Field
                name={fieldName}
                label={localizationService.toLanguageString('studyProgram.coordinator')}
                component={ComboBox}
                loading={coordinator?.loading}
                data={coordinator?.availableCoordinators}
                onFilter={(e) => this.props.loadAvailableCoordinators({ keyword: e.keyword, index })}
                onAddClick={() => this.handleOpenUserSearchDialog(fieldArrayRenderProps, index)}
                textField='text'
                valueField='id'
                width='276px'
              />
              <DeleteButton
                onClick={() => this.handleRemoveUser(index, fieldArrayRenderProps)}
                disabled={fieldArrayRenderProps.value.length === 1}
              />
            </StackLayout>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddUser(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </ProgramUsersContainer>
    );
  }

  renderAdministrators(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <ProgramUsersContainer>
        <ProgramUsersTitle>{localizationService.toLanguageString('studyProgram.administrators')}</ProgramUsersTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = studyProgramFieldArrayNames.ADMINISTRATORS + '[' + index + ']';
          const administrator = this.props.administrators[index];
          return (
            <StackLayout key={index} orientation='horizontal' align='left' valign='top'>
              <Field
                name={fieldName}
                label={localizationService.toLanguageString('studyProgram.administrator')}
                component={ComboBox}
                loading={administrator?.loading}
                data={administrator?.availableAdministrators}
                onFilter={(e) => this.props.loadAvailableAdministrators({ keyword: e.keyword, index })}
                onAddClick={() => this.handleOpenUserSearchDialog(fieldArrayRenderProps, index)}
                textField='text'
                valueField='id'
                width='276px'
              />
              <DeleteButton onClick={() => this.handleRemoveUser(index, fieldArrayRenderProps)}/>
            </StackLayout>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddUser(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </ProgramUsersContainer>
    );
  }

  renderCommitteeMembers(fieldArrayRenderProps) {
    const localizationService = provideLocalizationService(this);
    return (
      <ProgramUsersContainer>
        <ProgramUsersTitle>{localizationService.toLanguageString('studyProgram.committeeMembers')}</ProgramUsersTitle>
        {fieldArrayRenderProps.value?.map((item, index) => {
          const fieldName = studyProgramFieldArrayNames.COMMITTEE_MEMBERS + '[' + index + ']';
          const committeeMember = this.props.committeeMembers[index];
          return (
            <StackLayout key={index} orientation='horizontal' align='left' valign='top'>
              <Field
                name={fieldName}
                label={localizationService.toLanguageString('studyProgram.committeeMember')}
                component={ComboBox}
                loading={committeeMember?.loading}
                data={committeeMember?.availableCommitteeMembers}
                onFilter={(e) => this.props.loadAvailableCommitteeMembers({ keyword: e.keyword, index })}
                onAddClick={() => this.handleOpenUserSearchDialog(fieldArrayRenderProps, index)}
                textField='text'
                valueField='id'
                width='276px'
              />
              <DeleteButton onClick={() => this.handleRemoveUser(index, fieldArrayRenderProps)}/>
            </StackLayout>
          );
        })}
        <WrapLayout orientation='horizontal' align='left'>
          <Button
            type='button'
            icon='plus'
            onClick={() => this.handleAddUser(fieldArrayRenderProps)}
          />
        </WrapLayout>
      </ProgramUsersContainer>
    );
  }

  handleValidate(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.name) {
      errors.name = localizationService.toLanguageString('validation.required');
    }
    if (!values.code) {
      errors.code = localizationService.toLanguageString('validation.required');
    }
    if (values.validFrom && values.validTo && values.validFrom > values.validTo) {
      errors.validFrom = localizationService.toLanguageString('validation.impossibleSequence');
      errors.validTo = localizationService.toLanguageString('validation.impossibleSequence');
    }
    if (values.descriptionUrl && !this.isValidUrl(values.descriptionUrl)) {
      errors.descriptionUrl = localizationService.toLanguageString('validation.invalidLinkFormat');
    }
    if (values.coordinators?.length > 0 && values.coordinators.filter(element => element && element.id > 1).length === 0) {
      errors[`${studyProgramFieldArrayNames.COORDINATORS}[0]`] = localizationService.toLanguageString('validation.required');
    }
    if (!modified.name && !modified.code && !modified.validFrom && !modified.validTo) {
      if (validationResult?.errors?.name) {
        errors.name = validationResult?.errors?.name;
      }
      if (validationResult?.errors?.code) {
        errors.code = validationResult?.errors?.code;
      }
      if (validationResult?.errors?.validFrom) {
        errors.validFrom = validationResult?.errors?.validFrom;
      }
      if (validationResult?.errors?.validTo) {
        errors.validTo = validationResult?.errors?.validTo;
      }
    }
    return errors;
  }

  handleAddUser(fieldArrayRenderProps) {
    fieldArrayRenderProps.onPush({
      value: { }
    });
    this.props.loadInputData({ dataConsumers: [{ index: fieldArrayRenderProps?.value?.length ?? 0, fieldArrayName: fieldArrayRenderProps?.name }] });
  }

  handleRemoveUser(index, fieldArrayRenderProps) {
    fieldArrayRenderProps.onRemove({
      index: index
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-programs') {
      this.props.navigate('/study_programs');
    }
  }

  handleCancel() {
    this.props.navigate(-1);
  }

  handleSubmit(payload) {
    this.props.saveStudyProgram(payload);
  }

  handleOpenUserSearchDialog(fieldArrayRenderProps, index) {
    this.props.openUserSearchDialog({
      onAdd: (payload) => fieldArrayRenderProps.onReplace({ index: index, value: { id: payload.id, text: payload.text } })
    });
  }

  isValidUrl = urlString => {
    const regex = new RegExp('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)');
    if (regex.test(urlString)) {
      return true;
    }

    return false;
  }
}

registerForLocalization(StudyProgramFormPage);

const mapStateToProps = state => ({
  loading: state.studyProgramFormPage.loading,
  studyProgram: state.studyProgramFormPage.studyProgram,
  coordinators: state.studyProgramFormPage.coordinators,
  administrators: state.studyProgramFormPage.administrators,
  committeeMembers: state.studyProgramFormPage.committeeMembers,
  validationResult: state.studyProgramFormPage.validationResult,
  availableDivisions: state.studyProgramFormPage.availableDivisions,
  availableDivisionsLoading: state.studyProgramFormPage.availableDivisionsLoading,
  availableFaculties: state.studyProgramFormPage.availableFaculties,
  availableFacultiesLoading: state.studyProgramFormPage.availableFacultiesLoading
});

const mapDispatchToProps = dispatch => ({
  loadStudyProgramFormPage: (payload) => dispatch(loadStudyProgramFormPage(payload)),
  saveStudyProgram: (payload) => dispatch(saveStudyProgram(payload)),
  loadAvailableCoordinators: (payload) => dispatch(loadAvailableCoordinators(payload)),
  loadAvailableAdministrators: (payload) => dispatch(loadAvailableAdministrators(payload)),
  loadAvailableCommitteeMembers: (payload) => dispatch(loadAvailableCommitteeMembers(payload)),
  cancelStudyProgramFormPage: () => dispatch(cancelStudyProgramFormPage()),
  openUserSearchDialog: (payload) => dispatch(openUserSearchDialog(payload)),
  loadInputData: (payload) => dispatch(loadInputData(payload)),
  loadAvailableDivisions: (payload) => dispatch(loadAvailableDivisions(payload)),
  loadAvailableFaculties: (payload) => dispatch(loadAvailableFaculties(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramFormPage));
