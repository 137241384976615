import * as React from 'react';
import { connect } from 'react-redux';
import { Field } from '@progress/kendo-react-form';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { ComboBox, DropDown, FilterForm, StackLayout, TextBox, WrapLayout } from '../../../ui';
import { filterMySurveys, clearMySurveysFilter, filterResidentsComboBox } from '../actions/my-survey-list-page-actions';
import { COMPETENCY_SURVEY_STUDENT_STATUS } from '../../../resources/competencySurveyStudentStatus';
import styled from 'styled-components';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { getCompetencySurveyStudentStatusColor } from '../../../utils/competencySurveyStudentStatusColor';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class MySurveyListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  render() {
    const {
      filter,
      clearMySurveysFilter,
      filterMySurveys,
      residentsComboBoxLoading,
      residentsComboBoxData,
      filterResidentsComboBox
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterMySurveys}
        onClear={clearMySurveysFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='340px'>
              <Field
                name='resident'
                width='340px'
                component={ComboBox}
                label={localizationService.toLanguageString('mySurvey.resident')}
                textField='fullName'
                dataItemKey='id'
                loading={residentsComboBoxLoading}
                data={residentsComboBoxData}
                onFilter={filterResidentsComboBox}
              />
              <Field
                name='status'
                width='340px'
                component={DropDown}
                itemRender={this.statusDropdownItemRender}
                label={localizationService.toLanguageString('mySurvey.status')}
                textField='text'
                dataItemKey='value'
                data={this.getStatuses()}
              />
            </StackLayout>
            <StackLayout width='340px'>
              <Field
                name='keyword'
                width='340px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  getStatuses() {
    const localizationService = provideLocalizationService(this);
    return Object.entries(COMPETENCY_SURVEY_STUDENT_STATUS).map(element => ({
      value: element[1],
      text: localizationService.toLanguageString(`competencySurveyStudentStatus.${element[1]}`)
    }));
  }

  statusDropdownItemRender(li, itemProps) {
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge
            rounded={'medium'}
            size='large'
            themeColor={getCompetencySurveyStudentStatusColor(itemProps.dataItem.value)}
            position='outside'
            align={{ vertical: 'top', horizontal: 'end' }}
          >
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.mySurveyListPage.filter,
  residentsComboBoxLoading: state.mySurveyListPage.residentsComboBoxLoading,
  residentsComboBoxData: state.mySurveyListPage.residentsComboBoxData
});

const mapDispatchToProps = dispatch => ({
  filterMySurveys: (payload) => dispatch(filterMySurveys(payload)),
  clearMySurveysFilter: () => dispatch(clearMySurveysFilter()),
  filterResidentsComboBox: (payload) => dispatch(filterResidentsComboBox(payload))
});

registerForLocalization(MySurveyListFilter);

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyListFilter);