import * as React from 'react';
import { connect } from 'react-redux';
import { ComboBox, FilterForm, WrapLayout, StackLayout, TextBox } from '../../../ui';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Field } from '@progress/kendo-react-form';
import {
  clearDesignationsFilter,
  filterDesignations,
  searchDesignationsFilterDepartments,
  searchDesignationsFilterResidents,
  searchDesignationsFilterStudyPrograms
} from '../actions/designation-list-page-actions';
import { getDesignationStatusColor } from '../../../utils/designationStatusColors';
import styled from 'styled-components';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';

const DropdownItemContainer = styled.div`
  height: 24px;
  padding-top: 8px;
`;

const StyledBadge = styled(Badge)`
  opacity: 75%;
  font-size: small;
`;

class DesignationListFilter extends React.PureComponent {

  constructor(props) {
    super(props);
    this.statusDropdownItemRender = this.statusDropdownItemRender.bind(this);
  }

  render() {
    const {
      filter,
      filterDesignations,
      clearDesignationsFilter,
      designationStatuses,
      searchDesignationsFilterStudyPrograms,
      filterStudyProgramsLoading,
      filterStudyPrograms,
      searchDesignationsFilterResidents,
      filterResidentsLoading,
      filterResidents,
      searchDesignationsFilterDepartments,
      filterDepartmentsLoading,
      filterDepartments
    } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <FilterForm
        initialValues={filter}
        onSubmit={filterDesignations}
        onClear={clearDesignationsFilter}
        localizationService={localizationService}
        title={localizationService.toLanguageString('grid.filterTitle')}
        uniqueKey={JSON.stringify(filter)}
        render={() => (
          <WrapLayout>
            <StackLayout width='400px' orientation='vertical'>
              <Field
                name='studyProgram'
                width='348px'
                component={ComboBox}
                label={`${localizationService.toLanguageString('designations.studyProgram')}`}
                textField='name'
                dataItemKey='id'
                loading={filterStudyProgramsLoading}
                data={filterStudyPrograms}
                onFilter={searchDesignationsFilterStudyPrograms}
              />
              <Field
                name='resident'
                width='348px'
                component={ComboBox}
                label={localizationService.toLanguageString('designations.resident')}
                textField='fullName'
                dataItemKey='id'
                loading={filterResidentsLoading}
                data={filterResidents}
                onFilter={searchDesignationsFilterResidents}
              />
              <Field
                name='keyword'
                width='348px'
                component={TextBox}
                label={localizationService.toLanguageString('custom.search')}
              />
            </StackLayout>
            <StackLayout width='400px' orientation='vertical'>
              <Field
                name='status'
                width='348px'
                component={ComboBox}
                label={localizationService.toLanguageString('designations.status')}
                textField='text'
                dataItemKey='id'
                data={designationStatuses}
                itemRender={this.statusDropdownItemRender}
              />
              <Field
                name='department'
                width='348px'
                component={ComboBox}
                label={localizationService.toLanguageString('designations.department')}
                textField='name'
                dataItemKey='id'
                loading={filterDepartmentsLoading}
                data={filterDepartments}
                onFilter={searchDesignationsFilterDepartments}
              />
            </StackLayout>
          </WrapLayout>
        )}
      />
    );
  }

  statusDropdownItemRender(li, itemProps) {
    let color = getDesignationStatusColor(itemProps.dataItem.id);
    const itemChildren = (
      <DropdownItemContainer>
        <BadgeContainer>
          <StyledBadge rounded={'medium'} size={'large'} themeColor={color} position={'outside'} align={{ vertical: 'top', horizontal: 'end' }}>
            {li.props.children}
          </StyledBadge>
        </BadgeContainer>
      </DropdownItemContainer>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }
}

const mapStateToProps = state => ({
  filter: state.designationListPage.filter,
  designationStatuses: state.designationListPage.designationStatuses,
  filterStudyProgramsLoading: state.designationListPage.filterStudyProgramsLoading,
  filterStudyPrograms: state.designationListPage.filterStudyPrograms,
  filterResidentsLoading: state.designationListPage.filterResidentsLoading,
  filterResidents: state.designationListPage.filterResidents,
  filterDepartmentsLoading: state.designationListPage.filterDepartmentsLoading,
  filterDepartments: state.designationListPage.filterDepartments
});

const mapDispatchToProps = dispatch => ({
  filterDesignations: (payload) => dispatch(filterDesignations(payload)),
  clearDesignationsFilter: () => dispatch(clearDesignationsFilter()),
  searchDesignationsFilterStudyPrograms: (payload) => dispatch(searchDesignationsFilterStudyPrograms(payload)),
  searchDesignationsFilterResidents: (payload) => dispatch(searchDesignationsFilterResidents(payload)),
  searchDesignationsFilterDepartments: (payload) => dispatch(searchDesignationsFilterDepartments(payload))
});

registerForLocalization(DesignationListFilter);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignationListFilter);
