import { provideLocalizationService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { Grid } from '../../shared/components';
import { GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { connect } from 'react-redux';
import { withRouter } from '../../../components/withRouter';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox, Text, WrapLayout } from '../../../ui';
import { addStudyPlanCycleManager, closeStudyPlanCycleManagersDeleteDialog, closeStudyPlanCycleManagersListAddDialog, deleteStudyPlanCycleManager, loadStudyPlanCycleManagersList, openStudyPlanCycleManagersDeleteDialog, openStudyPlanCycleManagersListAddDialog, searchAvailableCycleManagers } from '../actions';
import FormDialog from '../../../ui/components/application/form-dialog';
import { StackLayout } from '@progress/kendo-react-layout';
import { Field } from '@progress/kendo-react-form';
import { openUserSearchDialog } from '../../users/actions/user-search-dialog-actions';
import styled from 'styled-components';
import colors from '../../../ui/components/shared/colors';

const ManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

class StudyPlanCycleManagersList extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleAddDialogClose = this.handleAddDialogClose.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteConfirmDialogClose = this.handleDeleteConfirmDialogClose.bind(this);
    this.handleAddDialogSubmit = this.handleAddDialogSubmit.bind(this);
    this.validateAddManagerDialog = this.validateAddManagerDialog.bind(this);
    this.handleOpenUserSearchDialog = this.handleOpenUserSearchDialog.bind(this);
  }

  async componentDidMount() {
    await this.props.loadStudyPlanCycleManagersList({
      studyPlanId: this.props.studyPlanId,
      studyPlanCycleId: this.props.studyPlanCycleId
    });
  }

  render() {
    const { loading, studyPlanCycleManagers, isAddDialogVisible, isDeleteConfirmDialogVisible, managersListLoading, availableManagers, validationResult } = this.props;
    const localizationService = provideLocalizationService(this);
    return (
      <>
        <Grid
          loading={loading}
          data={studyPlanCycleManagers}
          isPageable={false}
          columns={[
            {
              field: 'cycleManager',
              className: 'align-left',
              headerClassName: 'align-left',
              title: localizationService.toLanguageString('StudyPlanCycle.cycleManager'),
              cell: this.renderManager
            }
          ]}
          actions={[{
            icon: 'delete',
            onClick: this.handleRemoveClick
          }]}>
          <GridToolbar>
            <Button themeColor='primary' onClick={this.props.openStudyPlanCycleManagersListAddDialog}>
              {localizationService.toLanguageString('custom.add')}
            </Button>
          </GridToolbar>
        </Grid>
        {isAddDialogVisible &&
          <FormDialog
            title={localizationService.toLanguageString('StudyPlanCycle.addCycleManager')}
            width={'500px'}
            onCancel={this.handleAddDialogClose}
            onSubmit={this.handleAddDialogSubmit}
            validator={this.validateAddManagerDialog}
            validationResult={validationResult}
            confirmButtonText={localizationService.toLanguageString('buttons.add')}
            closeButtonText={localizationService.toLanguageString('buttons.cancel')}
            minWidth={320}
            render={(formRenderProps) => (
              <StackLayout>
                <Field
                  name='manager'
                  label={localizationService.toLanguageString('StudyPlanCycle.manager')}
                  component={ComboBox}
                  textField='name'
                  dataItemKey='id'
                  loading={managersListLoading}
                  data={availableManagers}
                  onFilter={this.props.searchAvailableCycleManagers}
                  onAddClick={() => this.handleOpenUserSearchDialog(formRenderProps)}
                />
              </StackLayout>
            )}
          />
        }
        {isDeleteConfirmDialogVisible &&
          <Dialog title={localizationService.toLanguageString('buttons.delete')} onClose={this.handleDeleteConfirmDialogClose}>
            <Text>{localizationService.toLanguageString('StudyPlanCycle.confirmDeleteManager')}</Text>
            <DialogActionsBar>
              <Button onClick={this.handleDeleteConfirm} themeColor='primary'>
                {localizationService.toLanguageString('buttons.delete')}
              </Button>
              <Button onClick={this.handleDeleteConfirmDialogClose}>{localizationService.toLanguageString('buttons.cancel')}</Button>
            </DialogActionsBar>
          </Dialog>
        }
      </>
    );
  }

  renderManager(e) {
    const { className, dataItem } = e;
    const managerDetails = [dataItem.userStampNumber, dataItem.userEmail].filter(Boolean).join(', ');
    return (
      <td className={className}>
        <ManagerContainer>
          {dataItem.userFullName}
          <WrapLayout>
            <Text textColor={colors.GRAY_50} variant='caption'>
              {managerDetails}
            </Text>
          </WrapLayout>
        </ManagerContainer>
      </td>
    );
  }

  handleAddClick() {
    this.props.openStudyPlanCycleManagersListAddDialog();
  }

  handleRemoveClick(e) {
    this.props.openStudyPlanCycleManagersDeleteDialog({ selectedId: e.dataItem.id });
  }

  handleAddDialogClose() {
    this.props.closeStudyPlanCycleManagersListAddDialog();
  }

  handleDeleteConfirm() {
    this.props.deleteStudyPlanCycleManager();
  }

  handleDeleteConfirmDialogClose() {
    this.props.closeStudyPlanCycleManagersDeleteDialog();
  }

  handleAddDialogSubmit(data) {
    this.props.addStudyPlanCycleManager({ userId: data.manager.id });
  }

  handleOpenUserSearchDialog(formRenderProps) {
    this.props.openUserSearchDialog({
      onAdd: (payload) => formRenderProps.onChange('manager', { value: { id: payload.id, name: payload.text } })
    });
  }

  validateAddManagerDialog(values, localizationService, validationResult, modified) {
    const errors = {};
    if (!values.manager || !values.manager.id) {
      errors.manager = localizationService.toLanguageString('validation.required');
    } else if (validationResult?.errors?.userId && !modified?.manager) {
      errors.manager = validationResult.errors.userId;
    }
    return errors;
  }
}

const mapStateToProps = (state) => ({
  loading: state.studyPlanCycleManagersList.loading,
  studyPlanCycleManagers: state.studyPlanCycleManagersList.studyPlanCycleManagers,
  isAddDialogVisible: state.studyPlanCycleManagersList.isAddDialogVisible,
  isDeleteConfirmDialogVisible: state.studyPlanCycleManagersList.isDeleteConfirmDialogVisible,
  managersListLoading: state.studyPlanCycleManagersList.managersListLoading,
  availableManagers: state.studyPlanCycleManagersList.availableManagers,
  validationResult: state.studyPlanCycleManagersList.validationResult
});

const mapDispatchToProps = (dispatch) => ({
  loadStudyPlanCycleManagersList: (payload) => dispatch(loadStudyPlanCycleManagersList(payload)),
  openStudyPlanCycleManagersListAddDialog: () => dispatch(openStudyPlanCycleManagersListAddDialog()),
  addStudyPlanCycleManager: (payload) => dispatch(addStudyPlanCycleManager(payload)),
  closeStudyPlanCycleManagersListAddDialog: () => dispatch(closeStudyPlanCycleManagersListAddDialog()),
  openStudyPlanCycleManagersDeleteDialog: (payload) => dispatch(openStudyPlanCycleManagersDeleteDialog(payload)),
  deleteStudyPlanCycleManager: () => dispatch(deleteStudyPlanCycleManager()),
  closeStudyPlanCycleManagersDeleteDialog: () => dispatch(closeStudyPlanCycleManagersDeleteDialog()),
  searchAvailableCycleManagers: (payload) => dispatch(searchAvailableCycleManagers(payload)),
  openUserSearchDialog: (payload) => dispatch(openUserSearchDialog(payload))
});

registerForIntl(StudyPlanCycleManagersList);
registerForLocalization(StudyPlanCycleManagersList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyPlanCycleManagersList));