import axios from 'axios';
import { StudyPlanCycleStudentDepartmentsListActionTypes } from '..';

export const loadStudyPlanCycleStudentDepartmentsList = (payload) => async(dispatch, getState, { api }) => {
  const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId } = payload;
  dispatch({ type: StudyPlanCycleStudentDepartmentsListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_LIST_START });
  try {
    const studentDepartments = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments`);
    dispatch({
      type: StudyPlanCycleStudentDepartmentsListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_LIST_END,
      payload: {
        studyPlanId,
        studyPlanCycleId,
        studyPlanCycleStudentId,
        studentDepartments: studentDepartments
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleStudentDepartmentsListActionTypes.LOAD_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleStudentDepartmentDeleteConfirmDialog = (payload) => (dispatch, getState) => {
  const { studyPlanCycleDepartmentStudentId } = payload;
  dispatch({
    type: StudyPlanCycleStudentDepartmentsListActionTypes.OPEN_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DELETE_CONFIRM_DIALOG,
    payload: {
      studyPlanCycleDepartmentStudentId
    }
  });
};

export const closeStudyPlanCycleStudentDepartmentDeleteConfirmDialog = () => (dispatch, getState) => {
  dispatch({ type: StudyPlanCycleStudentDepartmentsListActionTypes.CLOSE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DELETE_CONFIRM_DIALOG });
};

export const deleteStudyPlanCycleStudentDepartment = () => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleStudentDepartmentsList;
  const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, deleteStudyPlanCycleDepartmentStudentId } = state;
  try {
    await api.remove(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments/${deleteStudyPlanCycleDepartmentStudentId}`);
    const studentDepartments = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments`);
    dispatch({
      type: StudyPlanCycleStudentDepartmentsListActionTypes.DELETE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT,
      payload: {
        studentDepartments
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      throw error;
    }
  }
};

export const submitDesignation = (payload) => async(dispatch, getState, { api }) => {
  const { studyPlanId, studyPlanCycleId, studyPlanCycleStudentId, studyPlanCycleDepartmentStudentId } = payload;
  dispatch({ type: StudyPlanCycleStudentDepartmentsListActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_DESIGNATION_START });
  try {
    await api.post(`api/study_plan_cycle_department_student/${studyPlanCycleDepartmentStudentId}/designation_submit`);
    const studentDepartments = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/students/${studyPlanCycleStudentId}/departments`);
    dispatch({
      type: StudyPlanCycleStudentDepartmentsListActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_DESIGNATION_END,
      payload: {
        studentDepartments
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleStudentDepartmentsListActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENTS_DESIGNATION_END });
      throw error;
    }
  }
};
