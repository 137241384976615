export default {
  LOAD_DESIGNATION_LIST_START: 'LoadDesignationListStart',
  LOAD_DESIGNATION_LIST_END: 'LoadDesignationListEnd',
  FILTER_DESIGNATION_LIST: 'FilterDesignationList',
  SORT_DESIGNATION_LIST: 'SortDesignationList',
  LOAD_DESIGNATION_LIST_DESIGNATION_STATUSES: 'LoadDesignationListDesignationStatuses',
  SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_START: 'SearchDesignationListFilterStudyProgramStart',
  SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_END: 'SearchDesignationListFilterStudyProgramEnd',
  SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_START: 'SearchDesignationListFilterResidentsStart',
  SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_END: 'SearchDesignationListFilterResidentsEnd',
  SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_START: 'SearchDesignationListFilterDepartmentsStart',
  SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_END: 'SearchDesignationListFilterDepartmentsEnd',
  SELECT_DESIGNATION_LIST: 'SelectDesignationList',
  SELECT_ALL_DESIGNATION_LIST: 'SelectAllDesignationList',
  TOGGLE_DESIGNATION_LIST_APPROVE_CONFIRM_DIALOG: 'ToggleDesignationListApproveConfirmDialog',
  APPROVE_SELECTED_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START: 'ApproveSelectedStudyPlanCycleStudentDepartmentDesignationStart',
  APPROVE_SELECTED_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END: 'ApproveSelectedStudyPlanCycleStudentDepartmentDesignationEnd'
};