import axios from 'axios';
import StudyPlanCycleDepartmentListActionTypes from './study-plan-cycle-department-list-action-types';

export const loadStudyPlanCycleDepartmentList = ({ studyPlanId, studyPlanCycleId }) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_START });
  const state = getState().studyPlanCycleDepartmentList;
  try {
    state.studyPlanCycleDepartmentsCancelToken?.cancel();
    state.studyPlanCycleDepartmentsCancelToken = axios.CancelToken.source();
    const studyPlanCycleDepartments = await api.get(`api/study_plans/${studyPlanId}/cycles/${studyPlanCycleId}/departments`, null, state.studyPlanCycleDepartmentsCancelToken.token);
    dispatch({
      type: StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_END,
      payload: studyPlanCycleDepartments?.data
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_END });
      throw error;
    }
  }
};

export const openStudyPlanCycleDepartmentConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({
    type: StudyPlanCycleDepartmentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_DEPARTMENT_CONFIRM_DIALOG,
    payload
  });
};

export const closeStudyPlanCycleDepartmentConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: StudyPlanCycleDepartmentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_DEPARTMENT_CONFIRM_DIALOG });
};

export const removeStudyPlanCycleDepartmentConfirmDialog = (payload) => async(dispatch, getState, { api }) => {
  const state = getState().studyPlanCycleDepartmentList;
  if (state.selectedStudyPlanCycleDepartmentId > 0) {
    dispatch({ type: StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_START });
    try {
      await api.remove(`api/study_plans/${payload.studyPlanId}/cycles/${payload.studyPlanCycleId}/departments/${state.selectedStudyPlanCycleDepartmentId}`);
      dispatch(loadStudyPlanCycleDepartmentList({ studyPlanId: payload.studyPlanId, studyPlanCycleId: payload.studyPlanCycleId }));
    } catch (error) {
      dispatch({ type: StudyPlanCycleDepartmentListActionTypes.LOAD_STUDY_PLAN_CYCLE_DEPARTMENT_LIST_END });
      throw error;
    }
  }
  dispatch({ type: StudyPlanCycleDepartmentListActionTypes.TOGGLE_STUDY_PLAN_CYCLE_DEPARTMENT_CONFIRM_DIALOG });
};