export const ROLE_PERMISSIONS = {
  CYCLES_READ: 'CyclesRead',
  CYCLES_WRITE: 'CyclesWrite',

  DEPARTMENTS_READ: 'DepartmentsRead',
  DEPARTMENTS_WRITE: 'DepartmentsWrite',

  STUDY_PROGRAMS_READ: 'StudyProgramsRead',
  STUDY_PROGRAMS_WRITE: 'StudyProgramsWrite',

  STUDY_PLANS_READ: 'StudyPlansRead',
  STUDY_PLANS_WRITE: 'StudyPlansWrite',

  STUDENTS_READ: 'StudentsRead',
  STUDENTS_WRITE: 'StudentsWrite',

  USERS_READ: 'UsersRead',
  USERS_WRITE: 'UsersWrite',

  THEORETICAL_PARTS_READ: 'TheoreticalPartsRead',
  THEORETICAL_PARTS_WRITE: 'TheoreticalPartsWrite',

  MY_STUDIES_READ: 'MyStudiesRead',

  MY_STUDENTS_READ: 'MyStudentsRead',
  MANAGED_STUDENTS_READ: 'ManagedStudentsRead',
  APPROVAL_REQUESTS_READ: 'ApprovalRequestsRead',
  MY_SURVEYS_READ: 'MySurveysRead',
  COMPETENCY_REQUESTS_READ: 'CompetencyRequestRead',

  COMPETENCIES_READ: 'CompetenciesRead',
  COMPETENCIES_WRITE: 'CompetenciesWrite',

  MY_COMPETENCIES_READ: 'MyCompetenciesRead',

  EVALUATORS_READ: 'EvaluatorsRead',

  NOTIFICATIONS_READ: 'NotificationsRead',
  NOTIFICATIONS_WRITE: 'NotificationsWrite',

  DESIGNATIONS_READ: 'DesignationsRead',
  DESIGNATIONS_WRITE: 'DesignationsWrite'
};