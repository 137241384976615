import * as React from 'react';
import { connect } from 'react-redux';
import { provideIntlService, provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout, WrapLayout, Text } from '../../../ui';
import { DateFormatter, ValueItem } from '../../shared/components';
import { loadCycleOverviewPage, selectCycleOverviewPageTab } from '../actions/cycle-overview-page-actions';
import { CYCLE_TYPES } from '../../../resources/cycleType';
import { CycleCaseList, CycleSkillAbilityList, CycleTheoreticalPartList } from '.';
import { withRouter } from '../../../components/withRouter';
import { Tooltip } from '@progress/kendo-react-tooltip';
import styled from 'styled-components';

const ValueText = styled(Text).attrs(props => ({
  variant: 'body2',
  textColor: props.theme.gray80,
  truncate: props.truncateValue ?? true
}))``;

class CycleOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.cycleId) {
      this.props.loadCycleOverviewPage({ cycleId: this.props.params.cycleId });
    }
  }

  componentDidUpdate() {
    const parsedValue = parseInt(this.props.params?.cycleId, 10);
    if (!isNaN(parsedValue) && parsedValue !== this.props.cycle?.id) {
      this.props.loadCycleOverviewPage({ cycleId: this.props.params.cycleId });
    }
  }

  render() {
    const { cycle, loading, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${cycle?.name}`
        }
        headerActions={
          canWrite &&
            <>
              <Button
                id='edit-button'
                type='button'
                themeColor='primary'
                onClick={this.handleEditClick}
                disabled={this.props.cycle?.isCopyingInProgress}
              >
                {localizationService.toLanguageString('custom.edit')}
              </Button>
              <Button
                id='copy-button'
                type='button'
                primary='false'
                onClick={this.handleCopyClick}
                disabled={this.props.cycle?.isCopyingInProgress}
              >
                {localizationService.toLanguageString('custom.copy')}
              </Button>
            </>
        }
        breadcrumbItems={[
          {
            id: 'cycles',
            text: localizationService.toLanguageString('cycle.cycles')
          },
          {
            id: 'cycle',
            text: localizationService.toLanguageString('cycle.cycle'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        warningMessage={this.props.cycle?.isCopyingInProgress && localizationService.toLanguageString('cycle.cycleIsBeingCopied')}
        summary={
          <StackLayout rowGap='8px'>
            {cycle?.englishName &&
              <ValueItem
                label={localizationService.toLanguageString('cycle.englishName')}
                labelWidth='170px'
                loading={loading}
              >
                {cycle.englishName}
              </ValueItem>
            }
            <ValueItem
              label={localizationService.toLanguageString('cycle.description')}
              labelWidth='170px'
              loading={loading}
            >
              <Text variant={'body2'} overflowWrap={true}>
                {cycle?.description}
              </Text>
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.coordinatingStudyProgram')}
              labelWidth='170px'
              loading={loading}
            >
              {cycle?.divisionName ? `${cycle.studyProgramName} (${cycle.divisionName})` : cycle?.studyProgramName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.cycleAdministrator')}
              labelWidth='170px'
              loading={loading}
            >
              {cycle?.administratorName}
            </ValueItem>
            {cycle?.studyPrograms?.length > 0 ||
              <ValueItem
                label={localizationService.toLanguageString('cycle.code')}
                labelWidth='170px'
                loading={loading}
              >
                {cycle?.code}
              </ValueItem>
            }
            {cycle?.studyPrograms?.length > 0 &&
              <ValueItem
                label={localizationService.toLanguageString('cycle.taughtIn')}
                labelWidth='170px'
                loading={loading}
              >
                <Tooltip position='bottom' content={this.renderTooltip}>
                  <ValueText title='true'>{cycle?.studyPrograms?.length + 1}</ValueText><span title='true' className='k-icon k-i-info'></span>
                </Tooltip>
              </ValueItem>
            }
            <ValueItem
              label={localizationService.toLanguageString('cycle.type')}
              labelWidth='170px'
              loading={loading}
            >
              {this.resolveCycleTypeName(cycle?.type)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.credits')}
              labelWidth='170px'
              loading={loading}
            >
              {cycle?.credits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.abbreviation')}
              labelWidth='170px'
              loading={loading}
            >
              {cycle?.abbreviation}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.years')}
              labelWidth='170px'
              loading={loading}
            >
              {this.appendYearsToString(cycle)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.validFrom')}
              labelWidth='170px'
              loading={loading}
            >
              {DateFormatter(cycle?.validFrom, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('cycle.validTo')}
              labelWidth='170px'
              loading={loading}
            >
              {DateFormatter(cycle?.validTo, intlService)}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('cycle.tabstripAbilitiesTitle')}/>
            <TabStripTab title={localizationService.toLanguageString('cycle.tabstripCasesTitle')}/>
            <TabStripTab title={localizationService.toLanguageString('cycle.tabstripTheoreticalPartsTitle')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 && <CycleSkillAbilityList cycleId={this.props.params.cycleId} canWrite={canWrite} isCopyingInProgress={this.props.cycle?.isCopyingInProgress}/>}
        {this.props.selectedTab === 1 && <CycleCaseList cycleId={this.props.params.cycleId} canWrite={canWrite} isCopyingInProgress={this.props.cycle?.isCopyingInProgress}/>}
        {this.props.selectedTab === 2 && <CycleTheoreticalPartList cycleId={this.props.params.cycleId} canWrite={canWrite} isCopyingInProgress={this.props.cycle?.isCopyingInProgress}/>}
      </OverviewPage>
    );
  }

  renderTooltip() {
    const allStudyPrograms = [{ studyProgramName: this.props.cycle.studyProgramName, code: this.props.cycle.code }, ...this.props.cycle?.studyPrograms];
    return (
      <StackLayout rowGap='0'>
        {allStudyPrograms?.map((element, index) => <div key={index}>{`${element.studyProgramName} ${element.code}`}</div>)}
      </StackLayout>
    );
  }

  resolveCycleTypeName(cycleType) {
    const localizationService = provideLocalizationService(this);
    switch (cycleType) {
      case CYCLE_TYPES.OPTIONAL:
        return localizationService.toLanguageString('cycleType.Optional');
      case CYCLE_TYPES.REQUIRED:
        return localizationService.toLanguageString('cycleType.Required');
      default:
        return '';
    }
  }

  appendYearsToString(cycle) {
    const years = [];
    if (cycle) {
      cycle.firstYear && years.push(1);
      cycle.secondYear && years.push(2);
      cycle.thirdYear && years.push(3);
      cycle.fourthYear && years.push(4);
      cycle.fifthYear && years.push(5);
      cycle.sixthYear && years.push(6);
      cycle.seventhYear && years.push(7);
    }
    return years.join(', ');
  }

  renderCases(cases) {
    const localizationService = provideLocalizationService(this);
    const loading = this.props.loading;
    return cases?.map((element, index) => (
      <WrapLayout key={'case' + index} orientation='vertical' rowGap='2px'>
        <ValueItem
          label={localizationService.toLanguageString('cycle.case')}
          labelWidth='130px'
          loading={loading}
        >
          {element.studyProgramCaseName}
        </ValueItem>
        <ValueItem
          label={localizationService.toLanguageString('cycle.minCaseRequiredNumber')}
          labelWidth='130px'
          loading={loading}
        >
          {element.minRequiredNumber}
        </ValueItem>
        <ValueItem
          label={localizationService.toLanguageString('cycle.caseDiagnoses')}
          labelWidth='130px'
          loading={loading}
        >
          {element.diagnoses}
        </ValueItem>
      </WrapLayout>
    ));
  }

  handleEditClick() {
    this.props.navigate(`/cycles/${this.props.params.cycleId}`);
  }

  handleCopyClick() {
    this.props.navigate(`/cycles/${this.props.params.cycleId}/copy`);
  }

  handleTabSelect(e) {
    this.props.selectCycleOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'cycles') {
      this.props.navigate('/cycles');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(CycleOverviewPage);

const mapStateToProps = state => ({
  cycle: state.cycleOverviewPage.cycle,
  loading: state.cycleOverviewPage.loading,
  selectedTab: state.cycleOverviewPage.selectedTab,
  canWrite: state.cycleOverviewPage.canWrite
});

const mapDispatchToProps = dispatch => ({
  loadCycleOverviewPage: (payload) => dispatch(loadCycleOverviewPage(payload)),
  selectCycleOverviewPageTab: (payload) => dispatch(selectCycleOverviewPageTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CycleOverviewPage));
