import StudentCycleOverviewActionTypes from '../actions/student-cycle-overview-action-types';

const initialState = {
  loading: false,
  studentCycleOverviewCancelToken: null,
  readOnly: false,
  studyPlanId: null,
  studyPlanCycleStudentId: null,
  cycle: null,
  cycleStatusHistory: null,
  skills: null,
  additionalSkills: null,
  cases: null,
  additionalCases: null,
  theoreticalParts: null,
  isSummaryExpanded: false,
  selectedTab: 0,
  availableDepartments: [],
  filteredAvailableDepartments: [],
  availableManagers: [],
  isEditDialogVisible: false,
  editDialogLoading: false,
  editDialogTitle: null,
  editDialogType: null,
  editDialogConfirmButtonText: null,
  editDialogForm: {
    manager: null,
    medicalHistoryNumber: '',
    date: null,
    showMentorFields: false
  },
  editDialogValidationResult: null,
  editDialogError: null,

  isDeleteDialogVisible: false,
  isDeleteDialogDeleting: false,
  deleteDialogConfirmationMessage: null,
  deleteDialogType: null,
  deleteDialogItemId: null,
  deleteDialogErrorMessage: null,

  isAddDialogVisible: false,
  addDialogLoading: false,
  addDialogType: null,
  addDialogTitle: null,
  addDialogErrorMessage: null,
  addDialogValidationResult: null,
  addDialogForm: {
    cycle: null,
    skill: null,
    case: null
  },
  availableCycles: [],
  filteredAvailableCycles: [],
  availableCycleSkills: [],
  availableCycleSkillsLoading: false,
  availableCycleCases: [],
  availableCycleCasesLoading: false,

  managerSurveys: null,
  managerApprovals: null,
  cycleDepartmentSurveys: null,
  cycleManagerSurvey: null,
  isConfirmSubmitDialogVisible: false,
  confirmSubmitDialogErrorMessage: null,
  skillsCompleted: false,
  casesCompleted: false,
  isCycleStatusHistoryExpanded: false,
  isConfirmApprovalRequestDialogVisible: false,
  selectedManagerForApprovalRequestUser: null,
  confirmApprovalRequestDialogErrorMessage: null,

  studentSkillsLoading: false,
  studentSkillsLoaded: false,
  studentCasesLoading: false,
  studentCasesLoaded: false,
  studentTheoreticalPartsLoading: false,
  studentTheoreticalPartsLoaded: false,
  studentCycleCompletionLoading: false,
  studentCycleCompletionLoaded: false,

  isRegisterTheoreticalPartDialogVisible: false,
  selectedTheoreticalPart: null,
  competencies: null,

  isSubmitDepartmentDesignationDialogVisible: false,
  selectedStudyPlanCycleDepartmentStudentIdForDesignation: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_START:
      return {
        ...state,
        loading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_END:
      return {
        ...state,
        loading: false,
        readOnly: action.payload?.readOnly ?? false,
        studyPlanId: action.payload?.studyPlanId,
        studyPlanCycleStudentId: action.payload?.studyPlanCycleStudentId,
        cycle: action.payload?.cycle,
        availableCycles: action.payload?.availableCycles,
        competencies: action.payload?.competencies,
        filteredAvailableCycles: action.payload?.availableCycles ?? [],
        availableDepartments: action.payload?.availableDepartments ?? [],
        filteredAvailableDepartments: action.payload?.availableDepartments ?? []
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_START:
      return {
        ...state,
        studentSkillsLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_SKILLS_TAB_END:
      return {
        ...state,
        studentSkillsLoading: false,
        studentSkillsLoaded: action.payload?.studentSkillsLoaded,
        cycle: action.payload?.cycle ?? state.cycle,
        skills: action.payload?.skills,
        additionalSkills: action.payload?.additionalSkills,
        skillsCompleted: action.payload?.skillsCompleted
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_START:
      return {
        ...state,
        studentCasesLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_CASES_TAB_END:
      return {
        ...state,
        studentCasesLoading: false,
        studentCasesLoaded: action.payload?.studentCasesLoaded,
        cycle: action.payload?.cycle ?? state.cycle,
        cases: action.payload?.cases,
        additionalCases: action.payload?.additionalCases,
        casesCompleted: action.payload?.casesCompleted
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_START:
      return {
        ...state,
        studentTheoreticalPartsLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PARTS_TAB_END:
      return {
        ...state,
        studentTheoreticalPartsLoading: false,
        studentTheoreticalPartsLoaded: action.payload?.studentTheoreticalPartsLoaded,
        cycle: action.payload?.cycle ?? state.cycle,
        theoreticalParts: action.payload?.theoreticalParts
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_START:
      return {
        ...state,
        studentCycleCompletionLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_COMPLETION_TAB_END:
      return {
        ...state,
        studentCycleCompletionLoading: false,
        studentCycleCompletionLoaded: action.payload?.studentCycleCompletionLoaded,
        cycleStatusHistory: action.payload?.cycleStatusHistory,
        managerSurveys: action.payload?.managerSurveys,
        managerApprovals: action.payload?.managerApprovals,
        cycleDepartmentSurveys: action.payload?.cycleDepartmentSurveys,
        cycleManagerSurvey: action.payload?.cycleManagerSurvey
      };
    case StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_DEPARTMENTS:
      return {
        ...state,
        filteredAvailableDepartments: action.payload?.availableDepartments
      };
    case StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_MANAGERS:
      return {
        ...state,
        availableManagers: action.payload?.availableManagers ?? []
      };
    case StudentCycleOverviewActionTypes.FILTER_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLES:
      return {
        ...state,
        filteredAvailableCycles: action.payload?.availableCycles
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_START:
      return {
        ...state,
        availableCycleSkillsLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_SKILLS_END:
      return {
        ...state,
        availableCycleSkills: action.payload?.availableCycleSkills,
        availableCycleSkillsLoading: false
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_START:
      return {
        ...state,
        availableCycleCasesLoading: true
      };
    case StudentCycleOverviewActionTypes.LOAD_STUDENT_CYCLE_OVERVIEW_AVAILABLE_CYCLE_CASES_END:
      return {
        ...state,
        availableCycleCases: action.payload?.availableCycleCases,
        availableCycleCasesLoading: false
      };
    case StudentCycleOverviewActionTypes.SELECT_STUDENT_CYCLE_OVERVIEW_TAB:
      return {
        ...state,
        selectedTab: action.payload?.selectedTab != null ? action.payload.selectedTab : state.selectedTab
      };
    case StudentCycleOverviewActionTypes.TOGGLE_STUDENT_CYCLE_OVERVIEW_SUMMARY_EXPANDED:
      return {
        ...state,
        isSummaryExpanded: !state.isSummaryExpanded
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG:
      return {
        ...state,
        isEditDialogVisible: true,
        editDialogTitle: action.payload?.editDialogTitle,
        editDialogType: action.payload?.editDialogType,
        editDialogConfirmButtonText: action.payload?.editDialogConfirmButtonText,
        editDialogForm: action.payload?.editDialogForm,
        editDialogValidationResult: action.payload?.editDialogValidationResult,
        editDialogError: action.payload?.editDialogError,
        editDialogLoading: action.payload?.editDialogLoading
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG:
      return {
        ...state,
        isEditDialogVisible: false,
        editDialogLoading: false
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_BEGIN:
      return {
        ...state,
        editDialogLoading: true,
        studentCycleCompletionLoaded: false
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_EDIT_DIALOG_SAVING_END:
      return {
        ...state,
        editDialogLoading: false
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG: {
      return {
        ...state,
        isDeleteDialogVisible: true,
        isDeleteDialogDeleting: false,
        deleteDialogConfirmationMessage: action.payload?.deleteDialogConfirmationMessage,
        deleteDialogType: action.payload?.deleteDialogType,
        deleteDialogItemId: action.payload?.deleteDialogItemId,
        deleteDialogErrorMessage: action.payload?.deleteDialogErrorMessage
      };
    }
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG: {
      return {
        ...state,
        isDeleteDialogVisible: false,
        isDeleteDialogDeleting: false
      };
    }
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_BEGIN:
      return {
        ...state,
        isDeleteDialogDeleting: true
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_DELETE_DIALOG_DELETING_END:
      return {
        ...state,
        isDeleteDialogDeleting: false
      };
    case StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_SKILLS:
      return {
        ...state,
        skills: action.payload?.skills ?? state.skills,
        additionalSkills: action.payload?.additionalSkills ?? state.additionalSkills,
        skillsCompleted: action.payload?.skillsCompleted ?? state.skillsCompleted,
        managerSurveys: action.payload?.managerSurveys ?? state.managerSurveys,
        managerApprovals: action.payload?.managerApprovals ?? state.managerApprovals,
        studentSkillsLoading: false
      };
    case StudentCycleOverviewActionTypes.UPDATE_STUDENT_CYCLE_OVERVIEW_CASES:
      return {
        ...state,
        cases: action.payload?.cases ?? state.cases,
        additionalCases: action.payload?.additionalCases ?? state.additionalCases,
        casesCompleted: action.payload?.casesCompleted ?? state.casesCompleted,
        managerSurveys: action.payload?.managerSurveys ?? state.managerSurveys,
        managerApprovals: action.payload?.managerApprovals ?? state.managerApprovals,
        studentCasesLoading: false
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG:
      return {
        ...state,
        addDialogType: action.payload?.addDialogType,
        addDialogTitle: action.payload?.addDialogTitle,
        isAddDialogVisible: true,
        addDialogLoading: action.payload?.addDialogLoading
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_ADD_DIALOG:
      return {
        ...state,
        isAddDialogVisible: false,
        addDialogLoading: false
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_BEGIN:
      return {
        ...state,
        addDialogLoading: true,
        studentCycleCompletionLoaded: false
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_ADD_DIALOG_SAVING_END:
      return {
        ...state,
        addDialogLoading: false
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_ADDITIONAL_CASE_ADD_DIALOG: {
      return {
        ...state,
        isAdditionalSkillAddDialogVisible: false
      };
    }
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_ADDITIONAL_CASE_ADD_DIALOG: {
      return {
        ...state,
        isAdditionalCaseAddDialogVisible: false,
        availableCycleCases: []
      };
    }
    case StudentCycleOverviewActionTypes.COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_START:
      return {
        ...state
      };
    case StudentCycleOverviewActionTypes.COMPLETE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_STUDENT_END:
      return {
        ...state
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG:
      return {
        ...state,
        isConfirmSubmitDialogVisible: true,
        confirmSubmitDialogErrorMessage: action.payload?.confirmSubmitDialogErrorMessage
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_SUBMIT_DIALOG:
      return {
        ...state,
        isConfirmSubmitDialogVisible: false,
        readOnly: action.payload?.readOnly ?? state.readOnly,
        cycle: action.payload?.cycle ?? state.cycle,
        cycleStatusHistory: action.payload?.cycleStatusHistory ?? state.cycleStatusHistory,
        studentCycleCompletionLoaded: false
      };
    case StudentCycleOverviewActionTypes.TOGGLE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY_EXPANDED:
      return {
        ...state,
        isCycleStatusHistoryExpanded: !state.isCycleStatusHistoryExpanded
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_CYCLE_STATUS_HISTORY:
      return {
        ...state,
        isCycleStatusHistoryExpanded: false
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG:
      return {
        ...state,
        isRegisterTheoreticalPartDialogVisible: true,
        selectedTheoreticalPart: action.payload.selectedTheoreticalPart
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_THEORETICAL_PART_SUBMIT_DIALOG:
      return {
        ...state,
        isRegisterTheoreticalPartDialogVisible: false,
        selectedTheoreticalPart: null
      };
    case StudentCycleOverviewActionTypes.CLEAR_STUDENT_CYCLE_OVERVIEW:
      return {
        ...initialState,
        selectedTab: action.payload?.selectedTab ?? initialState.selectedTab
      };
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_START:
      return state;
    case StudentCycleOverviewActionTypes.STUDENT_CYCLE_OVERVIEW_SUBMIT_APPROVAL_REQUEST_END:
      return {
        ...state,
        studentCycleCompletionLoaded: action?.payload?.studentCycleCompletionLoaded ?? state.studentCycleCompletionLoaded,
        confirmApprovalRequestDialogErrorMessage: action?.payload?.confirmApprovalRequestDialogErrorMessage
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG:
      return {
        ...state,
        selectedManagerForApprovalRequestUser:
        {
          managerUserId: action?.payload?.managerUserId,
          managerFullName: action?.payload?.manager
        },
        isConfirmApprovalRequestDialogVisible: true
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_APPROVAL_REQUEST_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmApprovalRequestDialogVisible: false
      };
    case StudentCycleOverviewActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START:
      return {
        ...state,
        loading: true
      };
    case StudentCycleOverviewActionTypes.SUBMIT_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END:
      return {
        ...state,
        loading: false,
        availableDepartments: action.payload?.availableDepartments
      };
    case StudentCycleOverviewActionTypes.OPEN_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG:
      return {
        ...state,
        isSubmitDepartmentDesignationDialogVisible: true,
        selectedStudyPlanCycleDepartmentStudentIdForDesignation: action.payload?.studyPlanCycleDepartmentStudentId
      };
    case StudentCycleOverviewActionTypes.CLOSE_STUDENT_CYCLE_OVERVIEW_DEPARTMENT_DESIGNATION_SUBMIT_DIALOG:
      return {
        ...state,
        isSubmitDepartmentDesignationDialogVisible: false,
        selectedStudyPlanCycleDepartmentStudentIdForDesignation: null
      };
    default:
      return state;
  }
};