import DesignationOverviewPageActionTypes from './designation-overview-page-action-types';
import axios from 'axios';
import { DESIGNATION_STATUS } from '../../../resources/designationStatus';

export const loadDesignationOverviewPage = (payload) => async(dispatch, getState, { api }) => {
  dispatch({ type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_START });
  const { designationId } = payload;
  const state = getState().designationOverviewPage;
  try {
    state.designationCancelToken?.cancel();
    state.designationCancelToken = axios.CancelToken.source();
    const designation = await api.get(`api/designation/${designationId}`, state.designationCancelToken.token);
    const designationDocumentPdf = await api.get(`api/designation/${designationId}/download`, {}, state.designationCancelToken.token, 'blob');
    const designationHistory = await api.get(`api/designation/${designationId}/history`, state.designationCancelToken.token);
    dispatch({
      type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_END,
      payload: {
        designation: {
          ...designation,
          designationHistory: designationHistory?.data ?? [],
          designationPdfObjectUrl: designationDocumentPdf ? URL.createObjectURL(designationDocumentPdf) : null
        }
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationOverviewPageActionTypes.LOAD_DESIGNATION_OVERVIEW_PAGE_END });
      throw error;
    }
  }
};

export const approveDesignation = (payload) => async(dispatch, getState, { api }) => {
  const { designationId } = payload;
  const state = getState().designationOverviewPage;
  const designation = state.designation;
  dispatch({ type: DesignationOverviewPageActionTypes.APPROVE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START });
  try {
    state.designationCancelToken?.cancel();
    state.designationCancelToken = axios.CancelToken.source();
    await api.post(`api/designation/${designationId}/approve`, state.designationCancelToken.token);
    const designationHistory = await api.get(`api/designation/${designationId}/history`, state.designationCancelToken.token);
    dispatch({
      type: DesignationOverviewPageActionTypes.APPROVE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END,
      payload: {
        designation: {
          ...designation,
          designationHistory: designationHistory.data ?? [],
          status: DESIGNATION_STATUS.APPROVED
        }
      }
    });
  } catch (error) {
    if (!(error instanceof axios.Cancel)) {
      dispatch({ type: DesignationOverviewPageActionTypes.APPROVE_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END });
      throw error;
    }
  }
};

export const cleanupDesignationOverviewPage = () => async(dispatch, getState) => {
  const state = getState().designationOverviewPage;
  URL.revokeObjectURL(state.designation?.designationPdfObjectUrl);
  dispatch({ type: DesignationOverviewPageActionTypes.CLEANUP_DESIGNATION_OVERVIEW_PAGE });
};