import DesignationListPageActionTypes from '../actions/designation-list-page-action-types';
import { pagingSettings } from '../../../utils/GridPagingUtils';

const initialState = {
  loading: false,
  designations: null,
  designationsCancelToken: null,
  skip: pagingSettings.paging.skip,
  take: pagingSettings.paging.take,
  total: 0,
  sorting: null,
  filter: null,
  designationStatuses: [],
  selectedDesignations: null,
  selectedAll: false,
  isConfirmDialogVisible: false,

  filterStudyProgramsLoading: false,
  filterStudyProgramsCancelToken: null,
  filterStudyPrograms: null,

  filterResidentsLoading: false,
  filterResidentsCancelToken: null,
  filterResidents: null,

  filterDepartmentsLoading: false,
  filterDepartmentsCancelToken: null,
  filterDepartments: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_START:
      return {
        ...state,
        loading: true
      };
    case DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_END:
      return {
        ...state,
        loading: false,
        designations: action.payload?.designations,
        total: action.payload.total,
        skip: action.payload.skip,
        take: action.payload.take
      };
    case DesignationListPageActionTypes.SORT_DESIGNATION_LIST:
      return {
        ...state,
        sorting: action.payload
      };
    case DesignationListPageActionTypes.FILTER_DESIGNATION_LIST:
      return {
        ...state,
        filter: action.payload,
        skip: initialState.skip,
        take: initialState.take
      };
    case DesignationListPageActionTypes.LOAD_DESIGNATION_LIST_DESIGNATION_STATUSES:
      return {
        ...state,
        designationStatuses: action.payload?.designationStatuses
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_START:
      return {
        ...state,
        filterStudyProgramsLoading: true
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_STUDY_PROGRAM_END:
      return {
        ...state,
        filterStudyProgramsLoading: false,
        filterStudyPrograms: action.payload?.studyPrograms
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_START:
      return {
        ...state,
        filterResidentsLoading: true
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_RESIDENTS_END:
      return {
        ...state,
        filterResidentsLoading: false,
        filterResidents: action.payload?.residents
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_START:
      return {
        ...state,
        filterDepartmentsLoading: true
      };
    case DesignationListPageActionTypes.SEARCH_DESIGNATION_LIST_FILTER_DEPARTMENTS_END:
      return {
        ...state,
        filterDepartmentsLoading: false,
        filterDepartments: action.payload?.departments
      };
    case DesignationListPageActionTypes.SELECT_DESIGNATION_LIST:
      return {
        ...state,
        designations: action.payload?.designations,
        selectedDesignations: action.payload?.selectedDesignations,
        selectedAll: action.payload?.selectedAll
      };
    case DesignationListPageActionTypes.SELECT_ALL_DESIGNATION_LIST:
      return {
        ...state,
        designations: action.payload?.designations,
        selectedDesignations: action.payload?.selectedDesignations,
        selectedAll: action.payload?.selectedAll
      };
    case DesignationListPageActionTypes.TOGGLE_DESIGNATION_LIST_APPROVE_CONFIRM_DIALOG:
      return {
        ...state,
        isConfirmDialogVisible: !state.isConfirmDialogVisible
      };
    case DesignationListPageActionTypes.APPROVE_SELECTED_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_START:
      return {
        ...state,
        loading: true
      };
    case DesignationListPageActionTypes.APPROVE_SELECTED_STUDY_PLAN_CYCLE_STUDENT_DEPARTMENT_DESIGNATION_END:
      return {
        ...state,
        loading: false,
        designations: action.payload?.designations
      };
    default:
      return state;
  }
};