import * as React from 'react';
import { connect } from 'react-redux';
import { provideLocalizationService, registerForLocalization, provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { OverviewPage, StackLayout } from '../../../ui';
import { ValueItem, DateFormatter } from '../../shared/components';
import { loadStudyProgramOverviewPage, selectStudyProgramOverviewPageTab } from '../actions/study-program-overview-page-actions';
import { StudyProgramCycleList, StudyProgramCaseList, StudyProgramSkillAbilityList, StudyProgramTheoreticalPartList } from '.';
import { withRouter } from '../../../components/withRouter';
import styled from 'styled-components';

const Link = styled.a`
  overflow-wrap: anywhere;
`;

class StudyProgramOverviewPage extends React.PureComponent {

  constructor() {
    super();
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleBreadcrumbItemClick = this.handleBreadcrumbItemClick.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    if (this.props.params?.studyProgramId) {
      this.props.loadStudyProgramOverviewPage({
        studyProgramId: this.props.params.studyProgramId
      });
    }
  }

  render() {
    const { studyProgram, loading, canWrite } = this.props;
    const localizationService = provideLocalizationService(this);
    const intlService = provideIntlService(this);
    return (
      <OverviewPage
        title={loading
          ? <Skeleton shape='text' style={{ width: 300, height: 28 }}/>
          : `${studyProgram?.name}`
        }
        headerActions={
          <>
            {canWrite &&
              <Button
                id='edit-button'
                type='button'
                themeColor='primary'
                onClick={this.handleEditClick}
              >
                {localizationService.toLanguageString('custom.edit')}
              </Button>
            }
          </>
        }
        breadcrumbItems={[
          {
            id: 'study-programs',
            text: localizationService.toLanguageString('studyProgram.studyPrograms')
          },
          {
            id: 'study-program',
            text: localizationService.toLanguageString('studyProgram.studyProgram'),
            disabled: true
          }
        ]}
        onBreadcrumbItemClick={this.handleBreadcrumbItemClick}
        onBackClick={this.handleBackClick}
        summary={
          <StackLayout rowGap='8px'>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.credits')}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.credits}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.years')}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.years}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.code')}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.code}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.validFrom')}
              labelWidth='170px'
              loading={loading}
            >
              {DateFormatter(studyProgram?.validFrom, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.validTo')}
              labelWidth='170px'
              loading={loading}
            >
              {DateFormatter(studyProgram?.validTo, intlService)}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.division')}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.divisionName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.faculty')}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.facultyDivisionName}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString('studyProgram.descriptionLink')}
              labelWidth='170px'
              loading={loading}
            >
              <Link href={studyProgram?.descriptionUrl} target='_blank' rel='noreferrer'>{studyProgram?.descriptionUrl}</Link>
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString(`studyProgram.${studyProgram?.coordinators?.length > 1 ? 'coordinators' : 'coordinator'}`)}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.coordinators?.map(element => element.name).join(', ')}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString(`studyProgram.${studyProgram?.coordinators?.length > 1 ? 'administrators' : 'administrator'}`)}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.administrators?.map(element => element.name).join(', ')}
            </ValueItem>
            <ValueItem
              label={localizationService.toLanguageString(`studyProgram.${studyProgram?.coordinators?.length > 1 ? 'committeeMembers' : 'committeeMember'}`)}
              labelWidth='170px'
              loading={loading}
            >
              {studyProgram?.committeeMembers?.map((element, index) => element.name).join(', ')}
            </ValueItem>
          </StackLayout>
        }
        tabs={
          <TabStrip selected={this.props.selectedTab} onSelect={this.handleTabSelect}>
            <TabStripTab title={localizationService.toLanguageString('studyProgram.tabstripCyclesTitle')}/>
            <TabStripTab title={localizationService.toLanguageString('studyProgram.tabstripAbilitiesTitle')}/>
            <TabStripTab title={localizationService.toLanguageString('studyProgram.tabstripCasesTitle')}/>
            <TabStripTab title={localizationService.toLanguageString('studyProgram.tabstripTheoreticalPartsTitle')}/>
          </TabStrip>
        }
      >
        {this.props.selectedTab === 0 && <StudyProgramCycleList studyProgramId={this.props.params.studyProgramId}/>}
        {this.props.selectedTab === 1 && <StudyProgramSkillAbilityList studyProgramId={this.props.params.studyProgramId}/>}
        {this.props.selectedTab === 2 && <StudyProgramCaseList studyProgramId={this.props.params.studyProgramId}/>}
        {this.props.selectedTab === 3 && <StudyProgramTheoreticalPartList studyProgramId={this.props.params.studyProgramId}/>}
      </OverviewPage>
    );
  }

  handleEditClick() {
    this.props.navigate(`/study_programs/${this.props.params.studyProgramId}`);
  }

  handleTabSelect(e) {
    this.props.selectStudyProgramOverviewPageTab({
      selected: e.selected
    });
  }

  handleBreadcrumbItemClick(e) {
    if (e.id === 'study-programs') {
      this.props.navigate('/study_programs');
    }
  }

  handleBackClick() {
    this.props.navigate(-1);
  }
}

registerForLocalization(StudyProgramOverviewPage);
registerForIntl(StudyProgramOverviewPage);

const mapStateToProps = state => ({
  studyProgram: state.studyProgramOverviewPage.studyProgram,
  loading: state.studyProgramOverviewPage.loading,
  selectedTab: state.studyProgramOverviewPage.selectedTab,
  canWrite: state.studyProgramOverviewPage.canWrite
});

const mapDispatchToProps = dispatch => ({
  loadStudyProgramOverviewPage: (payload) => dispatch(loadStudyProgramOverviewPage(payload)),
  selectStudyProgramOverviewPageTab: (payload) => dispatch(selectStudyProgramOverviewPageTab(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StudyProgramOverviewPage));
